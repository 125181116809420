/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { KTCard, KTSVG, KTCardBody } from '../../../../_metronic/helpers'
import { CreditProfile } from '../core/_models'
import { SchedulePaymentList } from './schedule-payment/SchedulePaymentList'
import { HistoryList } from './history/HistoryList'
import { InsuranceList } from './insurance/InsuranceList'
import { RegistrationStatusList } from './registration-status/RegistrationStatusList'
import { PaymentDetailList } from './payment-detail/PaymentDetailList'

type Props = {
    
}

const CreditProfileAccordion: React.FC<Props> = () => {
    const intl = useIntl()
    return (
        <>
            <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_5"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotune/arrows/arr064.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {/*CHI TIẾT THANH TOÁN KỲ 23 (SỐ KỲ TRONG HỢP ĐỒNG)*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY5' })}
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_5"
                        className="fs-6 collapse show ps-10"
                        data-bs-parent="#kt_accordion_2"
                        style={{ overflow: 'hidden' }}
                    >
                        <div>
                            <PaymentDetailList />
                            <div className="" style={{ float: 'right' }}>
                                <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/pay'}>
                                    {intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY16' })}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_1"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotune/arrows/arr064.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {/*KẾ HOẠCH THANH TOÁN*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY1' })}
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_1"
                        className="fs-6 collapse ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        <SchedulePaymentList />
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_2"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotune/arrows/arr064.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {/*LỊCH SỬ THANH TOÁN*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY2' })}
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_2"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        <HistoryList />
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_3"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotune/arrows/arr064.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {/*BẢO HIỂM XE*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY3' })}
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_3"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        <InsuranceList />
                    </div>
                </div>
                <div className="mb-5">
                    <div
                        className="accordion-header py-3 d-flex collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_item_4"
                    >
                        <span className="accordion-icon">
                            <KTSVG
                                className="svg-icon svg-icon-4"
                                path="/media/icons/duotune/arrows/arr064.svg"
                            />
                        </span>
                        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {/*TÌNH TRẠNG ĐĂNG KÝ XE*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY4' })}
                        </h3>
                    </div>
                    <div
                        id="kt_accordion_2_item_4"
                        className="collapse fs-6 ps-10"
                        data-bs-parent="#kt_accordion_2"
                    >
                        <RegistrationStatusList />
                    </div>
                </div>
            </div>
        </>
    )
}

export { CreditProfileAccordion }