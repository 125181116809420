/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { HistoryCreditProfile } from '../../core/_models'
import { HistoryWidget } from './HistoryWidget'
import { HistoryTable } from './HistoryTable'

type Props = {
    
}

const creditHistoryProfiles = [
    {
        Oid: '123',
        display: '1',
        code: '1000563945',
        payDate: '15/07/2020',
        payAmount: '12.650.000',
        expireDate: '08/01/2025',
        totalAmount: '800.000.000',
        beginAmount: '450.000.000',
        remainAmount: '425.350.000',
        url: '/media/cars/car-01.png',
        description: 'Thông tin khoản vay'
    },
    {
        Oid: '1234',
        display: '2',
        code: '1000563945',
        payDate: '23/05/2020',
        payAmount: '12.650.000',
        expireDate: '08/01/2025',
        totalAmount: '800.000.000',
        beginAmount: '438.000.000',
        remainAmount: '425.350.000',
        url: '/media/cars/car-02.png',
        description: 'Thông tin khoản vay'
    }
];

const HistoryList: FC<Props> = () => (
    <>
        <div className="d-xs-block d-sm-block d-md-block d-xl-none d-xlg-none">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                {creditHistoryProfiles.map((history: HistoryCreditProfile) => (
                        <HistoryWidget key={history.Oid} history={history} />
                ))}

                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>

        <div className="d-none d-xs-none d-sm-none d-md-none d-xl-block d-xlg-block">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                <HistoryTable history={creditHistoryProfiles} />
                
                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>
    </>
)

export { HistoryList }