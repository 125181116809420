/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { CreditProfile } from './core/_models'
import { ItemTable } from './ItemTable'
import { ItemWidget } from './ItemWidget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import './creditprofile.css';
type Props = {
    profile: CreditProfile
}

const CreditProfileWidget: React.FC<Props> = ({ profile }) => {
    const intl = useIntl()
    return (
        <div className="card hover-elevate-up mt-9 credit-profile_card">
            <div className="card-header border-bottom" style={{ backgroundColor:'' }}>
                <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {intl.formatMessage({ id: 'CREDITPROFILE.CONTRACT' })}: {profile.code}</h3>
            </div>
            <div className="card-body p-0" style={{ overflow: 'hidden' }}>
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-xl-9 col-md-9 col-sm-8 order-2 order-xs-2 order-sm-2 order-md-2 order-xl-2 p-0'>
                        <div>
                            <div className="d-xs-block d-sm-block d-md-block d-xl-none d-xlg-none">
                                <ItemWidget key={'itemWidget' + profile.Oid} profile={profile} />
                            </div>
                            <div className="d-none d-xs-none d-sm-none d-md-none d-xl-block d-xlg-block">
                                <ItemTable key={'itemTable' + profile.Oid} profile={profile} />
                            </div>
                        </div>
                        <div className="card-footer" style={{ float:"right" }}>
                            <button type="button" className="btn btn-lg btn-light m-2 credit-profile_button">
                                <KTSVG path='/media/icons/duotune/art/download.svg' className='credit-profile_icon svg-icon-1' />
                                {intl.formatMessage({ id: 'CREDITPROFILE.DOWNLOADDOCUMENT' })}
                            </button>
                            <Link className="btn btn-lg btn-primary m-2 credit-profile_button" type="button" to={'/credit-profile/detail'}>
                                {intl.formatMessage({ id: 'CREDITPROFILE.DETAILS' })}
                            </Link>
                        </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-xl-3 col-md-3 col-sm-4 order-1 order-xs-1 order-sm-1 order-md-1 order-xl-1 credit-profile_img border-right'>
                        <img
                            src={toAbsoluteUrl(profile.url)}
                            className='w-100 w-xs-100 w-sm-100 w-md-100 lg-100'
                            alt=''
                        />
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
            
        </div>
    )
}
export { CreditProfileWidget }
