/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Fade, Slide } from 'react-slideshow-image';
import "react-slideshow-image/dist/styles.css"

type Props = {
  className: string
}

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    /*height: '400px'*/
}
const imgStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
    /*height: '400px'*/
}
const slideImages = [
    {
        url: '/media/slides/demo-slide1.jpg',
        caption: 'Slide 1'
    },
    {
        url: '/media/slides/demo-slide2.jpg',
        caption: 'Slide 2'
    },
    {
        url: '/media/slides/demo-slide3.jpg',
        caption: 'Slide 3'
    },
    {
        url: '/media/slides/demo-slide4.jpg',
        caption: 'Slide 4'
    },
    {
        url: '/media/slides/demo-slide5.jpg',
        caption: 'Slide 5'
    },
];

const Slideshow = () => {
    return (
        <div className="slide-container">
            <Fade arrows={false} autoplay={true} duration={3000} pauseOnHover={false}>
                {slideImages.map((slideImage, index) => (
                    <div key={index}>
                        <img src={toAbsoluteUrl(slideImage.url)} style={{ ...imgStyle }} />
                    </div>
                ))}
            </Fade>
        </div>
    )
}

const CreditProfileSlideWidget = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-0 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-9'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <Slideshow />
    </div>
  </div>
)
export { CreditProfileSlideWidget }
