/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { RegistrationStatusMaterial } from '../../core/_models'
import { useIntl } from 'react-intl'

type Props = {
    registrationStatus: any
}

const RegistrationStatusTable: React.FC<Props> = ({ registrationStatus }) => {
    const intl = useIntl()
    return (
        <table className="table border table-rounded table-row-dashed table-row-gray-300 gy-3 gs-3">
            <thead>
                <tr className="fs-6 border border-light text-uppercase text-center"
                    style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}
                >
                    <th className="min-w-150px">{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSTABLE.KEY1' })}</th>
                    <th>{/*Lần gia hạn*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSTABLE.KEY2' })}</th>
                    <th>{/*Ngày hiệu lực đăng ký xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSTABLE.KEY3' })}</th>
                    <th>{/*Ngày hết hạn đăng ký xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSTABLE.KEY4' })}</th>
                    <th>{/*Ngày TFSVN gửi khách hàng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSTABLE.KEY5' })}</th>
                </tr>
            </thead>
            <tbody>
                {registrationStatus.map((item: RegistrationStatusMaterial) => (
                    <tr key={"registrationStatusitem" + item.Oid} className="border border-light text-center">
                        <td className="text-nowrap">{item.materialNumber}</td>
                        <td className="text-nowrap">{item.registerCode}</td>
                        <td className="text-nowrap">{item.applyDate}</td>
                        <td className="text-nowrap">{item.expireDate}</td>
                        <td className="text-nowrap">{item.toCustomerDate}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        )
}
export { RegistrationStatusTable }
