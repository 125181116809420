/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { CreditProfileAccordion } from './CreditProfileAccordion'
import { CreditProfileDetailTabs } from './CreditProfileDetailTabs'
import { CreditProfile } from '../core/_models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

const profile = {
    Oid: '123',
    code: '1000563945',
    materialNumber: '51B - 497.51',
    Trademark: 'TOYOTA',
    Carmodel: 'AVANZA PREMIO',
    Version: 'CVT',
    applyDate: '08/01/2022',
    expireDate: '08/01/2025',
    loanAmount: '450.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000",
}

const CreditProfileDetailWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle>{/*Thông tin chi tiết khoản vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY1' })}</PageTitle>

            <div className="card card-bordered mt-9">
                <div className="card-header">
                    <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY3' })}: {profile.code}</h3>
                </div>
                <div className="card-body">
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-2 order-xs-2 order-sm-2 order-md-2 order-xl-2'>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} >{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY2' })}:</span>
                                <span className="" style={{ color: '#e10a1d', fontWeight: 'bolder' }}>{profile.materialNumber}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" >{/*Thương hiệu*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY18' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Trademark}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Mẫu xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY19' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Carmodel}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Phiên bản*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY20' })}:</span>
                                <span className="">{profile.Version}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY4' })}: </span>
                                <span className="">{profile.applyDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày kết thúc hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY5' })}: </span>
                                <span className="">{profile.expireDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Số tiền vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY6' })}: </span>
                                <span className="">{profile.loanAmount} đ</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY7' })}: </span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.remainAmount} đ</span>
                            </div>

                            {/*<div className='rounded border'>*/}
                            {/*    <div className="card card-bordered">*/}
                            {/*        <div className="card-header">*/}
                            {/*            <div className="card-title">*/}{/*CHI TIẾT SỐ TIỀN THANH TOÁN*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY8' })}:</div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <div className="border-bottom">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4'>*/}
                            {/*                        <span className="me-2">*/}{/*Gốc*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY9' })}: </span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-end'>*/}
                            {/*                        <span className="text-end">{profile.totalAmout} đ</span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}
                            {/*            <div className="mt-5 border-bottom">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4'>*/}
                            {/*                        <span className="me-2">*/}{/*Lãi*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY10' })}: </span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-end'>*/}
                            {/*                        <span className="text-end">{profile.profitAmout} đ</span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}
                            {/*            <div className="mt-5 border-bottom">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4'>*/}
                            {/*                        <span className="me-2">*/}{/*Phạt trễ hạn*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY11' })}: </span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-end'>*/}
                            {/*                        <span className="text-end">0 đ</span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}
                            {/*            <div className="mt-5 border-bottom">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4'>*/}
                            {/*                        <span className="me-2">*/}{/*Tiền dư*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY12' })}: </span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-end'>*/}
                            {/*                        <span className="text-end">0 đ</span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}
                            {/*            <div className="mt-5 border-bottom">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4'>*/}
                            {/*                        <span className="me-2">*/}{/*Số ngày trễ hạn*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY13' })}: </span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-end'>*/}
                            {/*                        <span className="text-end">0</span>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}
                            {/*            <div className="mt-5">*/}
                            {/*                */}{/* begin::Row */}
                            {/*                <div className='row'>*/}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-4 text-center'>*/}
                            {/*                        <div className="mt-5">*/}{/*NGÀY ĐẾN HẠN THANH TOÁN*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY14' })}: </div>*/}
                            {/*                        <div className="mt-5">15/04/2023</div>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                    */}{/* begin::Col */}
                            {/*                    <div className='col-8 text-center'>*/}
                            {/*                        <div className="mt-5">*/}{/*SỐ TIỀN CẦN THANH TOÁN*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY15' })}: </div>*/}
                            {/*                        <div className="mt-5">12,672,809 đ</div>*/}
                            {/*                    </div>*/}
                            {/*                    */}{/* end::Col */}
                            {/*                </div>*/}
                            {/*                */}{/* end::Row */}
                            {/*            </div>*/}

                            {/*            <div className="mt-5">*/}
                            {/*                <Link className="btn btn-lg btn-light m-2" type="button" to={'/credit-profile/pay'}>*/}
                            {/*                    */}{/*Thanh toán ngay*/}{/*{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY16' })}*/}
                            {/*                </Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>
                        {/* end::Col */}
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-1 order-xs-1 order-sm-1 order-md-1 order-xl-1'>
                            <img
                                src={toAbsoluteUrl(profile.url)}
                                className='w-100 w-xs-100 w-sm-100 w-md-100 lg-100'
                                alt=''
                            />
                        </div>
                        {/* end::Col */}
                    </div>
                    {/* end::Row */}
                </div>
            </div>
            
            <div className="card card-bordered mt-9">
                <div className="card-body"
                >
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-12'>
                            <CreditProfileAccordion />
                            {/*<CreditProfileDetailTabs />*/}
                        </div>
                        {/* end::Col */}
                    </div>
                    {/* end::Row */}
                </div>
            </div>
        </>
    )
}

export { CreditProfileDetailWrapper }
