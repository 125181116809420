/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { EarlyRepaymentCreditProfile } from '../../credit-profile/core/_models'

const profile = {
    Oid: '123',
    code: '1000563945',
    materialNumber: '51B - 497.51',
    applyDate: '08/01/2021',
    expireDate: '08/01/2021',
    loanAmount: '800.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000",
    interestRate: "12.5 %",
    earlyInterestRate: "15.0 %"
}

const calculator = {
    interestAmout: "62.576.000",//Lãi phải trả
    earlyMonetaryFine: "5.399.521", //Phạt trả nợ sớm
    lateMonetaryFine: "0", //Phạt trễ hạn
    remainAmount: "0", //Tiền dư trên hợp đồng
    totalNeedPay: "459.956.000" //TỔNG SỐ TIỀN CẦN THANH TOÁN
}

const CalculatorOption1: FC = () => {
    const intl = useIntl()
    const tigger = "{default: 'click', lg: 'hover'}"
    return (
        <>

            <div className="justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6'>
                        <span className="me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Interestpayable' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 text-end'>
                        <span className="text-nowrap text-end">{calculator.interestAmout} đ
                            <i className="ms-2 text-primary bi bi-info-circle-fill"></i>
                        </span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
            <div className="mt-5 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6'>
                        <span className="me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.PrepaymentPenalty' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 text-end'>
                        <span className="text-nowrap text-end">{calculator.earlyMonetaryFine} đ
                            <i className="ms-2 text-primary bi bi-info-circle-fill"></i>
                        </span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
            <div className="mt-5 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6 '>
                        <span className="me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Penaltyinterest' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 text-end'>
                        <span className="text-end">{calculator.lateMonetaryFine} đ</span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
            <div className="mt-5 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6 '>
                        <span className="me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Excessamount' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 text-end'>
                        <span className="text-end">{calculator.remainAmount} đ</span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>

            <div className="mt-10 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6 '>
                        <span className="h5 me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Totalpayable' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 h5 text-end'>
                        <span className="text-end text-nowrap">{calculator.totalNeedPay} đ
                            <i className="ms-2 text-primary bi bi-info-circle-fill"></i>
                        </span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>


        </>
    )
}

export { CalculatorOption1 }
