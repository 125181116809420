/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { faSave } from '@fortawesome/free-solid-svg-icons'

type Props = {
    className: string
}

const SupportInfoWidget: React.FC<Props> = ({ className }) => {
    const intl = useIntl()

    return (
        <div className="card card-bordered mt-9">
            <div className="card-header">
                <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {/*Nội dung yêu cầu*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY1' })}</h3>
            </div>
            <div className="card-body">

                <div className="row align-items-start">
                    <div className="col-12">
                        <h4>{/*Quý khách vui lòng nhập thông tin theo hướng dẫn sau*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY2' })}</h4>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Tên chủ xe*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY3' })} <span className="text-danger">(*)</span></label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY3' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Số điện thoại liên lạc*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY4' })} <span className="text-danger">(*)</span></label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY4' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Địa chỉ email*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY5' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY5' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Địa chỉ*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY6' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY6' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Số hợp đồng*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY7' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY7' })} />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label className="form-label">{/*Biển số xe*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY8' })} <span className="text-danger">(*)</span></label>
                            <select className="form-select form-control-lg" aria-label={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY8' })}>
                                <option selected value="1">{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY8' })}...</option>
                                <option value="51B - 497.51">51B - 497.51</option>
                                <option value="52B - 527.41">52B - 527.41</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="mb-3 m-2">
                            <label className="form-label">{/*Chọn loại yêu cầu*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY9' })} <span className="text-danger">(*)</span></label>
                            <select className="form-select form-control-lg" aria-label={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY9' })}>
                                <option selected value="1">{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY9' })} ...</option>
                                <option value="1">Loại 1</option>
                                <option value="2">Loại 2</option>
                                <option value="3">Loại khác</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="mb-3 m-2">
                            <label className="form-label">{/*Nội dung yêu cầu*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY10' })} <span className="text-danger">(*)</span></label>
                            <textarea className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY10' })} rows={3}></textarea>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="mb-3 m-2">
                            <label className="form-label">{/*Tập tin đính kèm*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY11' })}</label>
                            <input type="file" multiple className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY11' })} />
                        </div>
                        <div className="text-muted">
                            {/*Tập đính kèm không được quá 3mb hoặc quá 5 tập tin,*/}
                            {/*định dạng pdf, doc, docx, png, jpeg*/}
                            {intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY12' })}
                        </div>
                    </div>

                    <div className="col-12 mt-12">
                        <button className="btn btn-light btn-lg" title={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY13' })} type="button">
                            <FontAwesomeIcon icon={faSave} className="fs-2s me-2" /> {/*Lưu*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY13' })}
                        </button>
                        <button className="btn btn-primary btn-lg" title={intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY14' })} type="button" style={{ marginLeft: '8px' }}>
                            <span></span> {/*Gửi*/}{intl.formatMessage({ id: 'SUPPORT.SUPPORTINFOWIDGET.KEY14' })}
                            </button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export { SupportInfoWidget }
