import React, { FC, useEffect, useRef, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {useIntl} from 'react-intl'
import { NotificationInner } from './NotificationInner'
import { NotifyItem } from './_Models'

const Notification: FC = () => {
    const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
    const element = useRef<HTMLDivElement | null>(null)
    const wrapperElement = useRef<HTMLDivElement | null>(null)
    const resultsElement = useRef<HTMLDivElement | null>(null)
    const suggestionsElement = useRef<HTMLDivElement | null>(null)
    const emptyElement = useRef<HTMLDivElement | null>(null)
    const intl = useIntl()

    return (
        <>
            <div
                id='kt_drawer_notify'
                className='bg-body'
                data-kt-drawer='true'
                data-kt-drawer-name='Notify'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_drawer_notify_toggle'
                data-kt-drawer-close='#kt_drawer_notify_close'
                data-kt-scroll='true'
            >
                <div className='card w-100 rounded-0'>
                    <div className='card-header pe-5'>
                        <div className='card-title'>
                            {intl.formatMessage({ id: 'DASHBOARD.NOTIFY.KEY1' })}
                        </div>

                        <div className='card-toolbar'>
                            <div className='btn btn-sm btn-icon btn-active-light-primary me-5'>
                                <KTSVG path='/media/icons/duotune/arrows/arr086.svg' className='svg-icon-2' />
                            </div>
                            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_notify_close'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                            </div>
                        </div>
                    </div>

                    <NotificationInner />
                </div>
            </div>
        </>
    )
}

export { Notification }
