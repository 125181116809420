import {FC, useState} from 'react'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { NotificationItem } from './NotificationItem'

import { NotifyItem } from './_Models'
type Props = {
    isDrawer?: boolean
}

const NotificationInner: FC<Props> = ({isDrawer = false}) => {

    const data = [
        {
            id: 'QA4',
            message: 'Thông báo lãi suất vay mua xe Toyota cho Khách hàng Doanh nghiệp – Tháng 04/2023',
            image: '/media/misc/announcement.png',
            link: 'https://www.tfsvn.com.vn/thong-bao-lai-suat-vay-mua-xe-toyota-cho-khach-hang-doanh-nghiep-thang-04-2023/',
            imageQA: '/media/qa/qa4.jpg',
            dateTime: '31/03/2023 08:00',
            type: 'qa',
            seen: false
        },
        {
            id: 'QA3',
            message: 'Thông báo lãi suất vay mua xe Toyota cho Khách hàng Cá Nhân – Tháng 04/2023',
            image: '/media/misc/announcement.png',
            link: 'https://www.tfsvn.com.vn/thong-bao-lai-suat-vay-mua-xe-toyota-cho-khach-hang-ca-nhan-thang-01-2023-2/',
            imageQA: '/media/qa/qa3.jpg',
            dateTime: '31/03/2023 08:00',
            type: 'qa',
            seen: false
        },
        {
            id: '1',
            message: 'Đã thanh toán kỳ 4 hợp đồng mã #1000563945',
            image: '/media/payment/momo.png',
            imageQA: '/media/misc/announcement.png',
            link: '',
            dateTime: '02/03/2023 15:00',
            type: '',
            seen: true
        },
        {
            id: 'QA2',
            message: 'Ưu đãi lãi suất khi mua xe Toyota Veloz Cross và Avanza Premio trong tháng 03/2023',
            image: '/media/misc/announcement.png',
            imageQA: '/media/qa/qa2.jpg',
            link: 'https://www.tfsvn.com.vn/uu-dai-lai-suat-khi-mua-xe-toyota-veloz-cross-va-avanza-premio-trong-thang-03-2023/',
            dateTime: '09/03/2023 08:00',
            type: 'qa',
            seen: false
        },
        {
            id: 'QA1',
            message: 'TFSVN ưu đãi vay, “Lãi suất như mơ, Chốt ngay Xế xịn”',
            image: '/media/misc/announcement.png',
            imageQA: '/media/qa/qa1.jpg',
            link: '',
            dateTime: '09/03/2023 08:00',
            type: 'qa',
            seen: true
        },

        {
            id: '11',
            message: 'Đã đến kỳ thanh toán kỳ 4 hợp đồng mã #1000563945',
            image: '/media/payment/needPay.png',
            imageQA: '/media/misc/announcement.png',
            link: 'https://www.tfsvn.com.vn/uu-dai-lai-suat-khi-mua-xe-toyota-veloz-cross-va-avanza-premio-trong-thang-03-2023/',
            dateTime: '01/03/2023 00:00',
            type: 'needpay',
            seen: true
        },
        {
            id: '2',
            message: 'Đã thanh toán kỳ 3 hợp đồng mã #1000563945',
            image: '/media/payment/momo.png',
            imageQA: '/media/misc/announcement.png',
            link: '',
            dateTime: '04/02/2023 14:00',
            type: '',
            seen: true
        },
        {
            id: '21',
            message: 'Đã đến kỳ thanh toán kỳ 3 hợp đồng mã #1000563945',
            image: '/media/payment/needPay.png',
            imageQA: '/media/misc/announcement.png',
            link: '',
            dateTime: '01/02/2023 00:00',
            type: 'needpay',
            seen: true
        },
        //{
        //    id: '3',
        //    message: 'Đã thanh toán kỳ 2 hợp đồng mã #1000563945',
        //    image: '/media/payment/momo.png',
        //    imageQA: '/media/misc/announcement.png',
        //    dateTime: '08/01/2023 20:12',
        //    type: '',
        //    seen: false
        //},
        //{
        //    id: '31',
        //    message: 'Đã đến kỳ thanh toán kỳ 2 hợp đồng mã #1000563945',
        //    image: '/media/payment/needPay.png',
        //    imageQA: '/media/misc/announcement.png',
        //    dateTime: '01/02/2023 00:00',
        //    type: 'needpay'
        //    seen: false
        //},

        //{
        //    id: '4',
        //    message: 'Đã thanh toán kỳ 1 hợp đồng mã #1000563945',
        //    image: '/media/payment/momo.png',
        //    imageQA: '/media/misc/announcement.png',
        //    dateTime: '02/03/2023 11:35',
        //    type: ''
        //    seen: false
        //},
        //{
        //    id: '41',
        //    message: 'Đã đến kỳ thanh toán kỳ 1 hợp đồng mã #1000563945',
        //    image: '/media/payment/needPay.png',
        //    imageQA: '/media/misc/announcement.png',
        //    dateTime: '01/01/2023 00:00',
        //    type: 'needpay'
        //    seen: false
        //},

    ]
//    return (
//        <>
//            <div className="p-5 sco">
//            {data.map((item : NotifyItem) => (
//                <NotificationItem key={item.id} notify={item} />
//            ))}
//            </div>
//        </>
//    )
    return (
        <div
            className='card-body p-2'
            id={isDrawer ? 'kt_drawer_notify_messenger_body' : 'kt_notify_messenger_body'}
        >
            <div
                className={clsx( { 'h-lg-auto': !isDrawer })}
                data-kt-element='messages'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies={
                    isDrawer
                        ? '#kt_drawer_notify_messenger_header, #kt_drawer_notify_messenger_footer'
                        : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_notify_messenger_header, #kt_notify_messenger_footer'
                }
                data-kt-scroll-wrappers={
                    isDrawer
                        ? '#kt_drawer_notify_messenger_body'
                        : '#kt_content, #kt_app_content, #kt_notify_messenger_body'
                }
                data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
            >

                {data.map((item: NotifyItem) => (
                    <NotificationItem key={item.id} notify={item} />
                ))}
            </div>

            
        </div>
    )

}

export { NotificationInner }
