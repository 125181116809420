/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from 'react'
import './Auth.css';
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { useIntl } from 'react-intl'
import ReCAPTCHA from "react-google-recaptcha";
import React from 'react'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Vui lòng nhập Số CMND/CCCD/Mã số thuế'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Vui lòng nhập mật khẩu'),
})

const initialValues = {
    email: 'admin@demo.com',
    password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password)
                saveAuth(auth)
                const { data: user } = await getUserByToken(auth.api_token)
                setCurrentUser(user)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login details are incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    const sitekey = "6LdOsR8lAAAAABC0IiALLI2EdbAZvnF4LNXeChmQ";
    const onChange = () => {
        if (!sitekey) {
            console.log("1");
        }
        else console.log("2");
    };
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <a
                href='https://toyotafinance.com.vn/#'
                className='logo-auth d-flex justify-content-center mb-12'
            >
                <img
                    src={toAbsoluteUrl('/media/misc/logo-auth-bg.png')}
                    style={{ width: '234px', height: '74px' }}
                >
                </img>
            </a>
            <div className='text-center'>
                <p className='auth_title text-white fw-semibold'>{intl.formatMessage({ id: 'AUTH.LOGIN.KEY1' })}</p>
            </div>
            {/* end::Heading */}

            <div className='form_login'>
                {/* begin::Form group */}
                <div className='fv-row mb-2' >
                    <input
                        placeholder={intl.formatMessage({ id: 'AUTH.LOGIN.KEY2' })}
                        //{...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control',
                            { 'is-invalid': formik.touched.email && formik.errors.email },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row mb-2'>
                    <input
                        type='password'
                        autoComplete='off'
                        placeholder={intl.formatMessage({ id: 'AUTH.LOGIN.KEY3' })}
                        //{...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control',
                            {
                                'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/*begin::Recaptcha */}
                <div className="auth_recaptcha d-flex justify-content-center mb-8">
                    <ReCAPTCHA
                        sitekey={sitekey}
                        onChange={onChange}
                    />
                </div>
                {/* end::Recaptcha */}

                {/* begin::Action */}
                <div className='auth_action d-grid'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.KEY4' })}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'AUTH.LOGIN.KEY6' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Action */}
            </div>
            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-2'>

                {/* begin::Link */}
                <Link to='/auth/forgot-password' className='auth_forgotpw mb-4'>
                    {intl.formatMessage({id: 'AUTH.LOGIN.KEY5' })}
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::QRCode */}
            <a
                href='https://toyotafinance.com.vn/#'
                className='logo-auth d-flex justify-content-center mb-12'
            >
                <img
                    src={toAbsoluteUrl('/media/misc/QRCode.jpg')}
                    style={{ width: '360px', height: '54px' }}
                >
                </img>
            </a>
            {/* end::QRCode */}
        </form>
    )
}
