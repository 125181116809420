import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import './Auth.css';
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { requestPassword } from '../core/_requests'
import { useIntl } from 'react-intl'
import OtpInput from 'react-otp-input';
import ReCAPTCHA from "react-google-recaptcha";

const initialValues = {
    email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Sai định dạng Số CMND/CCCD/Mã số thuế')
        .min(3, 'Nhập tối thiểu 3 ký tự')
        .max(50, 'Nhập tối đa 50 ký tự')
        .required('Vui lòng nhập Số CMND/CCCD/Mã số thuế'),
})

export type Props = {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
};

export function ForgotPasswordOTP() {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                requestPassword(values.email)
                    .then(({ data: { result } }) => {
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })
    const sitekey = "6LdOsR8lAAAAABC0IiALLI2EdbAZvnF4LNXeChmQ";
    const onChange = () => {
        if (!sitekey) {
            console.log("1");
        }
        else console.log("2");
    };
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <a
                href='https://toyotafinance.com.vn/#'
                className='logo-auth d-flex justify-content-center mb-12'
            >
                <img
                    src={toAbsoluteUrl('/media/misc/logo-auth-bg.png')}
                    style={{ width: '234px', height: '74px' }}
                >
                </img>
            </a>
            <div className='text-center'>
                <p className='text-white fw-semibold' style={{ fontSize: '16px' }}>{intl.formatMessage({ id: 'AUTH.FORGOTPASSWORD.KEY4' })}</p>
            </div>
            {/* end::Heading */}

            

            <div className='form_login'>
                {/* begin::OTP */}
                <div className="otp-group mb-4">
                    {[2, 3, 1, 5, 7, 9].map((digit, idx) => (
                        <input
                            key={idx}
                            type="text"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d{1}"
                            maxLength={2}
                            className="otp-input"
                            value={digit}
                        />
                    ))}
                </div>
                {/* End::OTP */}

                {/* begin::Action */}
                <div className='auth_action d-grid'>
                    <Link
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        to='/auth/reset-password'
                    >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.FORGOTPASSWORD.KEY5' })}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Vui lòng đợi trong giây lát...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Link>
                </div>
                {/* end::Action */}
            </div>

        </form>
    )
}
