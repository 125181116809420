/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import React from 'react'
import './OnlinePayment.css';
import { KTCard, KTSVG, KTCardBody } from '../../../_metronic/helpers'
import { OnlinePaymentTableWidget } from './OnlinePaymentTableWidget';
import { OnlinePaymentTableVISAWidget } from './OnlinePaymentTableVISAWidget'
import { OnlinePaymentTableMOMOWidget } from './OnlinePaymentTableMOMOWidget'
import { OnlinePaymentTableVNPAYWidget } from './OnlinePaymentTableVNPAYWidget'
import { useIntl } from 'react-intl'

type Props = {
    className: string
}

const OnlinePaymentAccordion: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const banks = [
        '/media/payment/abbank.jpg',
        '/media/payment/acbbank.jpg',
        '/media/payment/agribank.jpg',
        '/media/payment/american_express.jpg',
        '/media/payment/bacabank.jpg',
        '/media/payment/baovietbank.jpg',
        '/media/payment/dongabank.jpg',
        '/media/payment/eximbank.jpg',
        '/media/payment/gpbank.jpg',
        '/media/payment/hdbank.jpg',
        '/media/payment/jcb.jpg',
        '/media/payment/kienlongbank.jpg',
        '/media/payment/lienvietpostbank.jpg',
        '/media/payment/maritimebank.jpg',
        '/media/payment/mbbank.jpg',
        '/media/payment/namabank.jpg',
        '/media/payment/ncb.jpg',
        '/media/payment/oceanbank.jpg',
        '/media/payment/pgbank.jpg',
        '/media/payment/pvcombank.png',
        '/media/payment/sacombank.jpg',
        '/media/payment/scb_bank.jpg',
        '/media/payment/seAbank.jpg',
        '/media/payment/shbbank.jpg',
        '/media/payment/techcombank.jpg',
        '/media/payment/tienphongbank.jpg',
        '/media/payment/vibbank.jpg',
        '/media/payment/vietabank.jpg',
        '/media/payment/vietcombank.jpg',
        '/media/payment/vietinbank.jpg',
        '/media/payment/vpbank.jpg'
    ]
    const credits = [
        '/media/payment/jcb.jpg',
        '/media/payment/american_express.jpg',
        '/media/payment/mastercard.jpg'

    ]

    const momos = [
        '/media/payment/momo.png'
    ]

    const vnpays = [
        '/media/payment/VNPAY.png'
    ]

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-3'>
                <h2 className='card-title align-items-start fw-bold'>
                    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: 'ONLINEPAYMENT.Wesupportthefollowingpaymentmethods' })}</span>
                </h2>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_1"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-4"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4 text-uppercase">
                                {intl.formatMessage({ id: 'ONLINEPAYMENT.DometicATMCard' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_1"
                            className="fs-6 collapse show ps-10"
                            data-bs-parent="#kt_accordion_2"
                        >
                            <div className="OnlinePayment_Item--Content mb-8">
                                <div className="OnlinePayment_Item--Title mb-2">
                                    Cách thức thanh toán<br />
                                </div>
                                Quý khách có thể thanh toán trực tuyến với thẻ nội địa bằng cách nhập thông tin thẻ trực tiếp khi đến bước
                                yêu cầu thanh toán. Thông tin bao gồm số thẻ, tên chủ thẻ, ngày mở thẻ và số CVV nằm ở mặt sau thẻ.
                                Ngoài ra, quý khách cũng có thể liên kết thẻ nội địa thông qua các ví điện tử hiện nay để sử dụng.
                            </div>
                            <div className="OnlinePayment_Item--Title mb-2">
                               Các ngân hàng hỗ trợ thanh toán
                            </div>
                            <div>
                                {banks.map((bank) => (
                                    <img className="p-1" src={toAbsoluteUrl(bank)} alt='Bank' />
                                ))}
                            </div>
                            <OnlinePaymentTableWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
                        </div>
                    </div>
                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_2"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-4"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4 text-uppercase">
                                {intl.formatMessage({ id: 'ONLINEPAYMENT.VISA' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_2"
                            className="collapse fs-6 ps-10"
                            data-bs-parent="#kt_accordion_2"
                        >
                            <div className="OnlinePayment_Item--Content mb-8">
                                <div className="OnlinePayment_Item--Title mb-2">
                                    Cách thức thanh toán<br />
                                </div>
                                Quý khách có thể thanh toán trực tuyến với thẻ Visa bằng cách nhập thông tin thẻ trực tiếp khi đến bước
                                yêu cầu thanh toán. Thông tin bao gồm số thẻ, tên chủ thẻ, ngày mở thẻ và số CVV nằm ở mặt sau thẻ.
                                Ngoài ra, quý khách cũng có thể liên kết thẻ Visa thông qua các ví điện tử hiện nay để sử dụng.
                            </div>
                            <div className="OnlinePayment_Item--Title mb-2">
                                Các ngân hàng hỗ trợ thanh toán
                            </div>
                            <div>
                                {credits.map((bank) => (
                                    <img className="p-1" src={toAbsoluteUrl(bank)} alt='Bank' />
                                ))}
                            </div>
                            
                            <OnlinePaymentTableVISAWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
                        </div>
                    </div>
                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_3"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-4"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4 text-uppercase">
                                {intl.formatMessage({ id: 'ONLINEPAYMENT.Momo' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_3"
                            className="collapse fs-6 ps-10"
                            data-bs-parent="#kt_accordion_2"
                        >
                            <div className="OnlinePayment_Item--Content mb-8">
                                <div className="OnlinePayment_Item--Title mb-2">
                                    Cách thức thanh toán<br />
                                </div>
                                1. Quét mã: Tại quầy thanh toán, thu ngân sẽ cung cấp 1 mã QR.
                                Người dùng mở ứng dụng Ví MoMo và chọn "Quét Mã".
                                Sau khi quét mã của thu ngân, khách hàng nhập số tiền cần thanh toán và bấm xác nhận.<br />
                                2. Mã thanh toán: Với phương thức này, quý khách mở ứng dụng và chọn "Mã thanh toán".
                                1 mã thanh toán sẽ xuất hiện trên màn hình.
                                Quý khách đưa mã đó cho thu ngân để quét và hoàn tất thanh toán.
                            </div>
                            <div>
                                {momos.map((bank) => (
                                    <img className="p-1" src={toAbsoluteUrl(bank)} style={{height: "48px"} } alt='Bank' />
                                ))}
                            </div>
                            <OnlinePaymentTableMOMOWidget className='card-xxl-stretch mb-5 mb-xxl-8 p-0'/>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_4"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-4"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4 text-uppercase">
                                {intl.formatMessage({ id: 'ONLINEPAYMENT.VNPay' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_4"
                            className="collapse fs-6 ps-10"
                            data-bs-parent="#kt_accordion_2"
                        >
                            <div className="OnlinePayment_Item--Content mb-8">
                                <div className="OnlinePayment_Item--Title mb-2">
                                    Cách thức thanh toán<br />
                                </div>
                                1. Nếu Quý khách chưa có ví điện tử VNPAY, xin vui lòng:<br />
                                Bước 1: Tạo tài khoản ví điện tử VNPAY, bằng cách bấm vào “Tạo ví điện tử VNPAY” và thực hiện theo hướng dẫn.<br />
                                Bước 2: Quay lại trang Toyota Finance và thực hiện thanh toán, bằng cách bấm vào “Thanh toán ngay” và thực hiện theo hướng dẫn của chương trình.<br />
                                2. Nếu Quý khách đã có ví điện tử VNPAY, Quý khách thực hiện thanh toán bằng tài khoản ví điện tử VNPAY, bằng cách bấm vào “Thanh toán ngay” và thực hiện theo hướng dẫn của chương trình.
                            </div>
                            <div>
                                {vnpays.map((bank) => (
                                    <img className="p-1" src={toAbsoluteUrl(bank)} style={{ height: "48px" }} alt='Bank' />
                                ))}
                            </div>
                            <OnlinePaymentTableVNPAYWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
                        </div>
                    </div>

                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_5"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-5"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4 text-uppercase">
                                {intl.formatMessage({ id: 'ONLINEPAYMENT.transferpayments' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_5"
                            className="collapse fs-6 ps-10"
                            data-bs-parent="#kt_accordion_2"
                        >
                            <div className="OnlinePayment_Item--Content mb-8">
                                <div className="OnlinePayment_Item--Title mb-2">
                                    Thông tin chuyển khoản<br />
                                </div>
                                
                            </div>
                            <div>Người thụ hưởng: Nguyễn Văn B</div>
                            <div>Số tài khoản: xxxx-xxx-xxxxxx</div>
                            <div>Nơi mở tài khoản: Ngân hàng VB Bank</div>
                            <div>Nội dung chuyển khoản: Thanh toán đơn hàng xxxxxx</div>
                            <OnlinePaymentTableWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}
export { OnlinePaymentAccordion }