import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { Languages } from '../../../partials/layout/header-menus/Languages'
import { useLayout } from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
    const { config } = useLayout()
    return (
        <div className='app-navbar flex-shrink-0'>

            <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
                <Search />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div className={clsx('position-relative', btnClass)} id='kt_drawer_notify_toggle'>
                    <KTSVG path='/media/icons/duotune/general/notification.svg' className={btnIconClass} />
                    <span className="badge badge-primary" style={{ position: "absolute", top: "-5px", left: "20px"} }>3</span>
                </div>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
                    <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
                    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
                </div>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
                </div>
                <HeaderUserMenu />
            </div>
        </div>
    )
}

export { Navbar }
