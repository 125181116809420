/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { SupportInfoWidget } from './SupportInfoWidget'


const SupportPage: FC = () => (
    <>
        <SupportInfoWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
    </>
)
const SupportWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <SupportPage />
    </>
  )
}

export {SupportWrapper}
