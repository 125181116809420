/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { InsuranceCreditProfile } from '../../core/_models'

type Props = {
    insurance: InsuranceCreditProfile
}

const InsuranceWidget = ({ insurance }: Props) => {
    const intl = useIntl()
    return (
        <div className="card card-bordered mt-0">
            <div className="card-body">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY1' })}: </span>
                            <span className="text-nowrap">{insurance.materialNumber}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Số hợp đồng BH*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY2' })}: </span>
                            <span className="text-nowrap">{insurance.insuranceCode}</span>
                        </div>
                        <div className="mb-5 mb-md-0 d-flex justify-content-between">
                            <span className="me-2">{/*Cty Bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY3' })}: </span>
                            <span className="text-nowrap">{insurance.insuranceName}</span>
                        </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 ps-md-15'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY4' })}: </span>
                            <span className="text-nowrap">{insurance.applyDate}</span>

                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày hết hạn bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY5' })}: </span>
                            <span className="text-nowrap">{insurance.expireDate}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Số tiền bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY6' })}: </span>
                            <span className="text-nowrap">{insurance.insuranceAmount} đ</span>
                        </div>
                        <div className="mb-0 d-flex justify-content-between">
                            <span className="me-2">{/*Phí bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCEWIDGET.KEY7' })}: </span>
                            <span className="text-nowrap">{insurance.feeAmount} đ</span>
                        </div>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
        </div>
    )
}
export { InsuranceWidget }
