/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { OnlinePaymentMedthodWidget } from './OnlinePaymentMedthodWidget'
import { OnlinePaymentAccordion } from './OnlinePaymentAccordion'
import { OnlinePaymentTableWidget } from './OnlinePaymentTableWidget'
import { OnlinePaymentTableVISAWidget } from './OnlinePaymentTableVISAWidget'
import { OnlinePaymentTableMOMOWidget } from './OnlinePaymentTableMOMOWidget'
import { OnlinePaymentTableVNPAYWidget } from './OnlinePaymentTableVNPAYWidget'

const OnlinePaymentPage: FC = () => (
  <>
        {/* begin::Body */}
        <div className="card mt-9">
                <OnlinePaymentAccordion className='card-xxl-stretch mb-5 mb-xxl-8 p-0' />
        </div>
        {/* end::Body */}
  </>
)

const OnlinePaymentWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
          <OnlinePaymentPage />
    </>
  )
}

export { OnlinePaymentWrapper }
