/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { AccountInfoWidget } from './AccountInfoWidget'

import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'

const AccountInfoPage: FC = () => (
  <>
        <AccountInfoWidget className='card-xxl-stretch mb-5 mb-xxl-8' />
  </>
)

const AccountInfoWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
          <AccountInfoPage />
    </>
  )
}

export { AccountInfoWrapper }
