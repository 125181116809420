/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { EarlyRepaymentCreditProfile } from '../../credit-profile/core/_models'
import { CalculatorOption1 } from './CalculatorOption1'
import { CalculatorOption2 } from './CalculatorOption2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

const profile = {
    //Oid: '123',
    //code: '1000563945',
    //materialNumber: '51B - 497.51',
    //applyDate: '08/01/2021',
    //expireDate: '08/01/2021',
    //loanAmount: '800.000.000',
    //remainAmount: '300.000.000',
    //url: '/media/cars/car-01.png',
    //description: 'Thông tin khoản vay',
    //totalAmout: "800.000.000",
    //profitAmout: "500.000",
    //interestRate: "12.5 %",
    //earlyInterestRate: "15.0 %"

    Oid: '123',
    code: '1000563945',
    materialNumber: '51B - 497.51',
    Trademark: 'TOYOTA',
    Carmodel: 'AVANZA PREMIO',
    Version: 'CVT',
    applyDate: '08/01/2022',
    expireDate: '08/01/2025',
    loanAmount: '450.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000",
}

const calculator = {
    interestAmout: "62.576.000",//Lãi phải trả
    earlyMonetaryFine: "5.399.521", //Phạt trả nợ sớm
    lateMonetaryFine: "0", //Phạt trễ hạn
    remainAmount: "0", //Tiền dư trên hợp đồng
    totalNeedPay: "459.956.000" //TỔNG SỐ TIỀN CẦN THANH TOÁN
}

const EarlyRepaymentCalculatorWrapper: FC = () => {
    const intl = useIntl()
    const [state, setState] = useState({
        value: 1
    })

    const handleRadioButton = (value: number) => {
        setState({
            value: value
        });
    }
    return (
        <>
            <PageTitle>Thông tin chi tiết khoản vay</PageTitle>

            <div className="card card-bordered mt-9">
                <div className="card-header">
                    <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.LicensePlates' })}: {profile.materialNumber}</h3>
                </div>
                <div className="card-body">
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-2 order-xs-2 order-sm-2 order-md-2 order-xl-2'>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} >{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY2' })}:</span>
                                <span className="" style={{ color: '#e10a1d', fontWeight: 'bolder' }}>{profile.materialNumber}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" >{/*Thương hiệu*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY18' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Trademark}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Mẫu xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY19' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Carmodel}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Phiên bản*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY20' })}:</span>
                                <span className="">{profile.Version}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY4' })}: </span>
                                <span className="">{profile.applyDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày kết thúc hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY5' })}: </span>
                                <span className="">{profile.expireDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Số tiền vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY6' })}: </span>
                                <span className="">{profile.loanAmount} đ</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY7' })}: </span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.remainAmount} đ</span>
                            </div>
                        </div>
                        {/* end::Col */}
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-1 order-xs-1 order-sm-1 order-md-1 order-xl-1'>
                            <img
                                src={toAbsoluteUrl(profile.url)}
                                className='w-100 w-xs-100 w-sm-100 w-md-100 lg-100'
                                alt=''
                            />
                        </div>
                        {/* end::Col */}
                    </div>
                    {/* end::Row */}
                    
                    <div className='rounded border'>
                        <div className="card card-bordered">
                            <div className="card-header" style={{ backgroundColor: 'rgb(244, 248, 251)' }}>
                                <div className="card-title" style={{ color: "#000000" }} >{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Detailsofearlyrepaymentamount' })}</div>
                            </div>
                            <div className="card-body">

                                {/* begin::Row */}
                                <div className='row'>
                                    {/* begin::Col */}
                                    <div className='col-xl-12 col-md-12 mb-9'>

                                        <div className='row'>
                                            <span className="col-xl-2 col-md-12">
                                                <input className="form-check-input align-middle" type="radio" name="calculatorType" id="calculatorTypeChecked"
                                                    onChange={() => handleRadioButton(1)}
                                                    checked={state.value === 1}
                                                />
                                                <label className="m-5" htmlFor="calculatorTypeChecked">
                                                    {intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Liquidation' })}
                                                </label>
                                            </span>
                                            <span className="col-xl-10 col-md-12 ">
                                                <input className="form-check-input align-middle" type="radio" name="calculatorType" id="calculatorTypeChecked2"
                                                    onChange={() => handleRadioButton(2)}
                                                    checked={state.value === 2}
                                                />
                                                <label className="m-5" htmlFor="calculatorTypeChecked2">
                                                    {intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.PrincipalReduction' })}
                                                </label>

                                            </span>
                                        </div>

                                        <div className="separator my-10"></div>

                                        {state.value === 1 && (
                                            <div>
                                            <CalculatorOption1 />
                                                <div className="mt-9">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.moveto' })} <i className="text-primary bi bi-info-circle-fill"></i> {intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.fordetails' })}</div>
                                            </div>
                                        )
                                        }
                                        {state.value === 2 && (
                                            <CalculatorOption2 />
                                        )
                                        }

                                        <div className="mt-5">
                                            <Link className="btn btn-lg btn-primary m-2 float-end" type="button" to={'/credit-profile/pay'}>
                                                {intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.checkout' })}
                                            </Link>
                                        </div>
                                    </div>
                                            
                                    
                                </div>
                                {/* end::Row */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export { EarlyRepaymentCalculatorWrapper }
