import { useState } from 'react'
import {FC} from 'react'
import { toAbsoluteUrl, KTSVG } from '../../../helpers'
import { Link } from 'react-router-dom'
import { NotifyItem } from './_Models'

type Props = {
    notify: NotifyItem
}

const NotificationItem: React.FC<Props> = ({ notify }) => {
    const state = {
        showModal: false
    }

    const isNeedPay = (notify.type === 'needpay')
    const isQA = (notify.type === 'qa')
    const isSeenBackGround = notify.seen ? 'bg-light-primary' : 'bg-light-warning'

    const GoToPayProfile: FC = () => {
        return(
            <>
                <div className={`alert alert-dismissible ${isSeenBackGround} border border-primary p-5 mb-1`}>
                    <div className='btn btn-sm btn-icon btn-active-primary float-end'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                    </div>
                    <Link className="" to={'/credit-profile/pay'}>
                        <div className="d-flex flex-row">
                            <div className="me-4 mb-5 mb-sm-0">
                                <img
                                    src={toAbsoluteUrl(notify.image)}
                                    className='w-40px'
                                    alt='' />
                            </div>
                            <div className="text-primary pe-0 pe-sm-0">
                                <span>{notify.message}</span>
                                <h5 className="mb-1">{notify.dateTime}</h5>
                            </div>
                        </div>
                    </Link>
                </div>
            </>
        )
    }
    const GoDetailProfile: FC = () => {
        return (
            <>
                <div className={`alert alert-dismissible ${isSeenBackGround} border border-primary p-5 mb-1`}>
                    <div className='btn btn-sm btn-icon btn-active-primary float-end'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                    </div>
                    <Link className="" to={'/credit-profile'}>
                        <div className="d-flex flex-row">
                            <div className="me-4 mb-5 mb-sm-0">
                                <img
                                    src={toAbsoluteUrl(notify.image)}
                                    className='w-40px'
                                    alt=''
                                />
                            </div>
                            <div className="text-primary pe-0 pe-sm-0">
                                <span>{notify.message}</span>
                                <h5 className="mb-1">{notify.dateTime}</h5>
                            </div>
                        </div>
                    </Link>
                </div>
            </>
        )
    }
    const GoQA: FC = () => {
        return (
            <>
                <div className={`alert alert-dismissible ${isSeenBackGround} border border-primary p-5 mb-1`}>
                    <div className='btn btn-sm btn-icon btn-active-primary float-end'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                    </div>
                    <a className="" target="_blank" href={notify.link}>
                        <div className="d-flex flex-row">
                            <div className="me-4 mb-5 mb-sm-0">
                                <img
                                    src={toAbsoluteUrl(notify.image)}
                                    className='w-40px'
                                    alt=''
                                />
                            </div>
                            <div className="text-primary pe-0 pe-sm-0">
                                <span>{notify.message}</span>
                                <h5 className="mb-1">{notify.dateTime}</h5>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <img
                                src={toAbsoluteUrl(notify.imageQA)}
                                className='mg-fluid'
                                alt=''
                            />
                        </div>
                    </a>
                </div>
            </>
        )
    }
    return (
        <>
            {isNeedPay ? <GoToPayProfile /> : isQA ? <GoQA /> : <GoDetailProfile/> }
        </>
    )
}

export { NotificationItem }
