/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { Dropdown1 } from '../../../_metronic/partials'
import { useThemeMode } from '../../../_metronic/partials'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useIntl } from 'react-intl'
type Props = {
    className: string
    chartColor: string
    chartHeight: string
}

const DashboardChartWidget: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
    const intl = useIntl()
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()

    const totalPlaning = intl.formatMessage({ id: 'DASHBOARD.PLANING' })
    const totalActual = intl.formatMessage({ id: 'DASHBOARD.ACTUAL' })
    const unit = intl.formatMessage({ id: 'DASHBOARD.UNIT' })


    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart1 = new ApexCharts(chartRef.current, chart1Options(totalPlaning, totalActual, unit, chartColor, chartHeight))
        if (chart1) {
            chart1.render()
        }

        return chart1
    }

    useEffect(() => {
        const chart1 = refreshChart()

        return () => {
            if (chart1) {
                chart1.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, mode])

    return (
        <div className={`card ${className}`}>
            {/* begin::Beader */}
            <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: 'DASHBOARD.TOTALPAYABLE' })}</span>
                </h3>

                {/*<div className='card-toolbar'>*/}
                {/*    */}{/* begin::Menu */}
                {/*    <button*/}
                {/*        type='button'*/}
                {/*        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'*/}
                {/*        data-kt-menu-trigger='click'*/}
                {/*        data-kt-menu-placement='bottom-end'*/}
                {/*        data-kt-menu-flip='top-end'*/}
                {/*    >*/}
                {/*        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />*/}
                {/*    </button>*/}
                {/*    <Dropdown1 />*/}
                {/*    */}{/* end::Menu */}
                {/*</div>*/}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
                {/* begin::Chart */}
                <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
                {/* end::Chart */}

                {/* begin::Items */}
                <div className='mt-5'>
                    {/* begin::Item */}
                    <div className='d-flex flex-stack mb-5'>
                        {/* begin::Section */}
                        <div className='d-flex align-items-center me-2'>
                            {/* begin::Symbol */}
                            <div className='symbol symbol-50px me-3'>
                                <div className='symbol-label bg-light'>
                                    <img
                                        src={toAbsoluteUrl('/media/svg/brand-logos/calendar.svg')}
                                        alt=''
                                        className='h-50'
                                    />
                                </div>
                            </div>
                            {/* end::Symbol */}

                            {/* begin::Title */}
                            <div>
                                <div className='fs-6 text-gray-800 fw-bold'>
                                    {intl.formatMessage({ id: 'DASHBOARD.UPCOMINGPAYMENTDATE' })}
                                </div>
                                <div className='fs-7 text-muted fw-semibold mt-1'>20/04/2023</div>
                            </div>
                            {/* end::Title */}
                        </div>
                        {/* end::Section */}

                        {/* begin::Label */}
                        {/*<div className='badge badge-light fw-semibold py-4 px-3'>+82$</div>*/}
                        {/* end::Label */}
                    </div>
                    {/* end::Item */}

                    {/* begin::Item */}
                    <div className='d-flex flex-stack mb-5'>
                        {/* begin::Section */}
                        <div className='d-flex align-items-center me-2'>
                            {/* begin::Symbol */}
                            <div className='symbol symbol-50px me-3'>
                                <div className='symbol-label bg-light'>
                                    <img
                                        src={toAbsoluteUrl('/media/svg/brand-logos/dollar.svg')}
                                        alt=''
                                        className='h-50'
                                    />
                                </div>
                            </div>
                            {/* end::Symbol */}

                            {/* begin::Title */}
                            <div>
                                <div className='fs-6 text-gray-800 fw-bold'>
                                    {intl.formatMessage({ id: 'DASHBOARD.UPCOMINGPAYMENTAMOUNT' })}
                                </div>
                                <div className='fs-7 text-muted fw-semibold mt-1'>25.000.000 đ</div>
                            </div>
                            {/* end::Title */}
                        </div>
                        {/* end::Section */}

                        {/* begin::Label */}
                        {/*<div className='badge badge-light fw-semibold py-4 px-3'>+82$</div>*/}
                        {/* end::Label */}
                    </div>
                    {/* end::Item */}

                    {/* begin::Item */}
                    <div className='d-flex flex-stack'>
                        {/* begin::Section */}
                        <div className='d-flex align-items-center me-2'>
                            {/* begin::Symbol */}
                            <div className='symbol symbol-50px me-3'>
                                <div className='symbol-label bg-light'>
                                    <img
                                        src={toAbsoluteUrl('/media/svg/brand-logos/wrench.svg')}
                                        alt=''
                                        className='h-50'
                                    />
                                </div>
                            </div>
                            {/* end::Symbol */}

                            {/* begin::Title */}
                            <div className='py-1'>
                                <div className='fs-6 text-gray-800 fw-bold'>
                                    {intl.formatMessage({ id: 'DASHBOARD.UPCOMINGINSURANCEPAYMENT' })}
                                </div>

                                <div className='fs-7 text-muted fw-semibold mt-1'>24/03/2023</div>
                            </div>
                            {/* end::Title */}
                        </div>
                        {/* end::Section */}

                        {/* begin::Label */}
                        {/*<div className='badge badge-light fw-semibold py-4 px-3'>+82$</div>*/}
                        {/* end::Label */}
                    </div>
                    {/* end::Item */}
                </div>
                {/* end::Items */}
            </div>
            {/* end::Body */}
        </div>
    )
}

const chart1Options = (totalPlaning: string, totalActual: string, unit:string, chartColor: string, chartHeight: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = getCSSVariableValue('--bs-info-light')
    return {
        series: [
            {
                color: baseColor,
                name: totalPlaning,
                data: [500, 450, 400, 350, 300, 250],
            },
            {
                name: totalActual, // 'Tổng dư nợ thực tế',
                data: [500, 450, 450, 450, 350, 300],
                color: "#ff0000"
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'line',
            height: 400,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'straight',
            show: true,
            width: 3,
            colors: [baseColor],
        },
        xaxis: {
            categories: ['01/2023', '02/2023', '03/2023', '04/2023', '05/2023', '06/2023'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' ' + unit
                },
            },
        },
        colors: [lightColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: baseColor,
            strokeWidth: 3,
        },
    }
}

export { DashboardChartWidget }
