/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
    className: string
}

const DashboardTableWidget: React.FC<Props> = ({ className }) => {
    const intl = useIntl()

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0' >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: 'DASHBOARD.UPCOMING' })}</span>
                    {/*<span className='mt-1 fw-semibold fs-7'>{intl.formatMessage({ id: 'DASHBOARD.youhave2contractstopay' })}</span>*/}
                </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                <div className='tab-content'>
                    {/* begin::Tap pane */}
                    <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table gy-1 gs-1'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className="fs-6 border border-light text-uppercase text-center"
                                        style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}>
                                        <th className='text-center fw-bolder  mb-1 text-uppercase'>{intl.formatMessage({ id: 'DASHBOARD.CONTRACT' })}</th>
                                        <th className='text-center fw-bolder  mb-1 text-uppercase'>{intl.formatMessage({ id: 'DASHBOARD.DUEDATE' })}</th>
                                        <th className='text-center fw-bolder  mb-1 text-uppercase'>{intl.formatMessage({ id: 'DASHBOARD.PAYABLE' })}</th>
                                        <th className='text-center fw-bolder  mb-1 text-uppercase'>{intl.formatMessage({ id: 'DASHBOARD.DETAILS' })}</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody className="border border-light text-center" style={{ verticalAlign: 'middle' }}>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='symbol symbol-45px me-2'>
                                                <span>1000563945
                                                </span>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='mb-1 fs-6'>
                                                20/04/2023
                                            </span>
                                        </td>
                                        <td className='text-center mb-1 fs-6'>25.000.000 đ</td>
                                        <td className='text-center'>
                                                <Link
                                                    className={
                                                        `btn btn-sm btn-icon btn-bg-light btn-active-color-primary `
                                                    }
                                                    to='/credit-profile/detail'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-2'
                                                    />
                                                </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='symbol symbol-45px me-2'>
                                                <span>1000563945</span>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='mb-1 fs-6'>
                                                12/07/2023
                                            </span>
                                        </td>
                                        <td className='text-center mb-1 fs-6'>23.500.000 đ</td>
                                        <td className='text-center'>
                                            <Link
                                                className={
                                                    `btn btn-sm btn-icon btn-bg-light btn-active-color-primary `
                                                }
                                                to='/credit-profile/detail'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='symbol symbol-45px me-2'>
                                                <span>1000693715</span>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='mb-1 fs-6'>
                                                28/4/2023
                                            </span>
                                        </td>
                                        <td className='text-center mb-1 fs-6'>27.500.000 đ</td>
                                        <td className='text-center'>
                                            <Link
                                                className={
                                                    `btn btn-sm btn-icon btn-bg-light btn-active-color-primary `
                                                }
                                                to='/credit-profile/detail'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='symbol symbol-45px me-2'>
                                                <span>1000693715</span>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='mb-1 fs-6'>
                                               15/7/2023
                                            </span>
                                        </td>
                                        <td className='text-center mb-1 fs-6'>24.000.000 đ</td>
                                        <td className='text-center'>
                                            <Link
                                                className={
                                                    `btn btn-sm btn-icon btn-bg-light btn-active-color-primary `
                                                }
                                                to='/credit-profile/detail'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='symbol symbol-45px me-2'>
                                                <span>1000563945</span>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='mb-1 fs-6'>
                                                25/09/2023
                                            </span>
                                        </td>
                                        <td className='text-center mb-1 fs-6'>26.000.000 đ</td>
                                        <td className='text-center'>
                                            <Link
                                                className={
                                                    `btn btn-sm btn-icon btn-bg-light btn-active-color-primary `
                                                }
                                                to='/credit-profile/detail'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                    {/* begin::Tap pane */}
                    <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='border-0'>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-150px'></th>
                                        <th className='p-0 min-w-140px'></th>
                                        <th className='p-0 min-w-110px'></th>
                                        <th className='p-0 min-w-50px'></th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Brad Simmons
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>React, HTML</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-success'>Approved</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Popular Authors
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Most Successful</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-warning'>In Progress</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Active Customers
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-danger'>Rejected</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                    {/* begin::Tap pane */}
                    <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='border-0'>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-150px'></th>
                                        <th className='p-0 min-w-140px'></th>
                                        <th className='p-0 min-w-110px'></th>
                                        <th className='p-0 min-w-50px'></th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Bestseller Theme
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Best Customers</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>ReactJS, Ruby</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-warning'>In Progress</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Active Customers
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-danger'>Rejected</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                New Users
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>Laravel,Metronic</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-primary'>Success</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='symbol symbol-45px me-2'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                Popular Authors
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Most Successful</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                                        <td className='text-end'>
                                            <span className='badge badge-light-warning'>In Progress</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='#'
                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

export { DashboardTableWidget }
