/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { CreditProfile } from '../credit-profile/core/_models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
type Props = {
    profile: CreditProfile
}

const ItemWidget: React.FC<Props> = ({ profile }) => {
    const intl = useIntl()
    return (
        <div className="card card-bordered mt-0">
            <div className="card-body">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.LICENSEPLATES' })}: </span>
                            <span className="text-nowrap">{profile.materialNumber}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.PAYABLE' })}: </span>
                            <span className="text-nowrap">{profile.remainAmount} đ</span>
                        </div>
                        <div className="mb-5 mb-md-0 d-flex justify-content-between">
                            <span className="me-2">{/*Ngàu hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.EFFECTIVEFROM' })}: </span>
                            <span className="text-nowrap">{profile.applyDate}</span>
                        </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 ps-md-15'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Số tiền vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.LOANAMOUNT' })}: </span>
                            <span className="text-nowrap text-primary fw-bold">{profile.loanAmount} đ</span>
                        </div>
                        <div className="mb-0 d-flex justify-content-between">
                            <span className="me-2">{/*Số tiền phải trả*/}{intl.formatMessage({ id: 'CREDITPROFILE.NeedPay' })}: </span>
                            <span className="text-nowrap text-primary fw-bold">{profile.needPay} đ</span>
                        </div>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
        </div>
    )
}
export { ItemWidget }
