/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import './AccountInfo.css';
import { Link } from 'react-router-dom'

type Props = {
    className: string
}

const AccountInfoWidget: React.FC<Props> = ({ className }) => {
    const intl = useIntl()

    return (
        <div className="card card-bordered mt-9">
            <div className="card-header">
                <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {/*Hồ sơ của tôi*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY1' })}</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="col-12 ">
                        <div className="d-flex align-items-center mb-3 m-2">
                            <div className='symbol symbol-100px symbol-lg-130px symbol-fixed position-relative'>
                                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                            </div>
                            <div className='account-info_header'>
                                <div className='account-info_name'>Nguyễn Tuấn Hải</div>
                                {/*<div className='account-info_description'>{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY2' })}</div>*/}
                            </div>
                        </div>
                        <div className='account-info_content'>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-5">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY3' })}:</div>
                                    <div className="col-7 text-end">Nguyễn</div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-6">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY4' })}:</div>
                                    <div className="col-6 text-end">Hải</div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-6">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY10' })}:</div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                            <label className="form-check-label text-black" htmlFor="flexRadioDefault1">
                                                {intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY11' })}
                                            </label>
                                        </div>
                                        <div className="form-check ms-5">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label className="form-check-label text-black" htmlFor="flexRadioDefault2">
                                                {intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY12' })}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-4">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY5' })}:</div>
                                    <div className="col-8 text-end">hainguyen@gmail.com</div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-6">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY6' })}:</div>
                                    <div className="col-6 text-end">0938 633 788</div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-4">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY7' })}:</div>
                                    <div className="col-8 text-end">146/4 Nguyễn Hữu Cảnh, P.8, Q.Bình Thạnh</div>
                                </div>
                            </div>
                            <div className='account-info_item justify-content-between m-5'>
                                <div className='row'>
                                    <div className="col-6">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY8' })}:</div>
                                    <div className="col-6 text-end">Hồ Chí Minh</div>
                                </div>
                            </div>
                            {/*<div className='account-info_item justify-content-between py-1'>*/}
                            {/*    <div className='row'>*/}
                            {/*        <div className="col-6">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY9' })}:</div>*/}
                            {/*        <div className="col-6 text-end">********</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <Link className="btn btn-primary mt-6" type="button" to={'/account-info-edit'}>
                                {intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY13' })}
                            </Link>
                            <Link className="btn btn-primary mt-6" type="button" to={'/change-password'}>
                                {intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY15' })}
                            </Link>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export { AccountInfoWidget }
