/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import {useIntl} from 'react-intl'
import { KTCardBody, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { CreditProfileDetailWrapper } from './details/CreditProfileDetailWrapper'
import { CreditProfileWrapper } from './CreditProfileWrapper'
import { PayCreditProfileWrapper } from './pay-credit-profile/PayCreditProfileWrapper'
import { NapasCreditWrapper } from './napas/NapasCreditWrapper'
import { NapasATMWrapper } from './napas/NapasATMWrapper'
import { MomoWrapper } from './napas/MomoWrapper'
import { VNPayWrapper } from './napas/VNPayWrapper'
import { TransferWrapper } from './napas/TransferWrapper'

const CreditProfileRoute: FC = () => {
    return (
        <Routes>
                <Route index
                    element={
                        <>
                            <CreditProfileWrapper />
                        </>
                    }
                />

                <Route
                    path='detail'
                    element={
                        <>
                            <CreditProfileDetailWrapper />
                        </>
                    }
                />
                <Route
                    path='pay'
                    element={
                        <>
                            <PayCreditProfileWrapper />
                        </>
                    }
                />
                <Route
                    path='pay-napas-credit'
                    element={
                        <>
                            <NapasCreditWrapper />
                        </>
                    }
                />
                <Route
                    path='pay-napas-atm'
                    element={
                        <>
                            <NapasATMWrapper />
                        </>
                    }
                />
                <Route
                    path='pay-momo'
                    element={
                        <>
                            <MomoWrapper />
                        </>
                    }
                />
                <Route
                    path='pay-vnpay'
                    element={
                        <>
                            <VNPayWrapper />
                        </>
                    }
            />
            <Route
                path='transfer'
                element={
                    <>
                        <TransferWrapper />
                    </>
                }
            />
        </Routes>
    )
}

export { CreditProfileRoute }
