/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import './AccountInfo.css';
import { NULL } from 'sass'


type Props = {
    className: string
}

const AccountInfoEditWidget: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const [avatar, setAvatar] = useState();
    const handleAvatar = (e: { target: { files: any } }) => {
        const file = e.target.files[0]
        file.preview = URL.createObjectURL(file)
        setAvatar(file)
    }


    return (
        <div className="card card-bordered mt-9">
            <div className="card-header">
                <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {/*Hồ sơ của tôi*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY1' })}</h3>
            </div>
            <div className="card-body">

                <div className="row align-items-start">
                    <div className="col-12">
                        <div className="d-flex align-items-center mb-3 m-2">
                            <div className='symbol symbol-100px symbol-lg-130px symbol-fixed position-relative'>
                                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                            </div>
                            <div className='account-info_header'>
                                <div className='account-info_name'>Nguyễn Tuấn Hải</div>
                                {/*<div className='account-info_description'>{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY2' })}</div>*/}
                                {/*<input type="file"*/}
                                {/*    accept='/image/*'*/}
                                {/*    onChange={handleAvatar}*/}
                                {/*/>*/}
                                {/*{avatar && (*/}
                                {/*    <img src={avatar.state. preview} alt='Metronic' />*/}
                                {/*)}*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Họ*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY3' })} <span className="text-danger">(*)</span></label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY3' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Tên*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY4' })} <span className="text-danger">(*)</span></label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY4' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Số điện thoại*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY5' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY5' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Email*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY6' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY6' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Địa chỉ*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY7' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY7' })} />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label htmlFor="" className="form-label">{/*Thành phố*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY8' })}</label>
                            <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY8' })} />
                        </div>
                    </div>
                    {/*<div className="col-12 col-sm-12 col-md-6 col-lg-6">*/}
                    {/*    <div className="mb-3 m-2">*/}
                    {/*        <label htmlFor="" className="form-label">*/}{/*Mật khẩu*/}{/*{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY9' })}</label>*/}
                    {/*        <input type="text" className="form-control form-control-lg" placeholder={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY9' })} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="mb-3 m-2">
                            <label className="form-label">{/*Chọn loại yêu cầu*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY10' })} <span className="text-danger">(*)</span></label>
                            <select className="form-select form-control-lg" aria-label={intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY10' })}>
                                <option selected value="1">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY10' })}</option>
                                <option value="1">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY11' })}</option>
                                <option value="2">{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY12' })}</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-12 mt-6">
                        <Link className="btn btn-primary  mt-6" type="button" to={'/account-info'}>
                            <FontAwesomeIcon icon={faSave} className="fs-2s me-2" /> {/*Lưu*/}{intl.formatMessage({ id: 'ACCOUNTINFO.ACCOUNTINFOWIDGET.KEY14' })}
                        </Link>
                    </div>

                </div>

            </div>
        </div>
    )
}

export { AccountInfoEditWidget }
