/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { CreditProfile } from '../credit-profile/core/_models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
type Props = {
    profile: CreditProfile
}

const ItemTable: React.FC<Props> = ({ profile }) => {
    const intl = useIntl()
    return (
        <table className="table border table-row-dashed table-row-gray-300 gy-3 gs-3">
            <thead>
                <tr className="fs-6 border border-light text-uppercase text-center"
                    style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}
                >
                    <th>{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.LICENSEPLATES' })}</th>
                    <th>{/*Ngày hết hạn bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.EFFECTIVETHROUGH' })}</th>
                    <th>{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.PAYABLE' })}</th>
                    <th>{/*Số tiền vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.LOANAMOUNT' })}</th>
                    <th>{/*Số tiền phải trả*/}{intl.formatMessage({ id: 'CREDITPROFILE.NeedPay' })}</th>
                </tr>
            </thead>
            <tbody>
                <tr className="border border-light text-center">
                    <td className="text-center text-nowrap">{profile.materialNumber}</td>
                    <td className="text-center text-nowrap">{profile.expireDate}</td>
                    <td className="text-end text-nowrap text-primary fw-bold">{profile.remainAmount} đ</td>
                    <td className="text-end text-nowrap text-primary fw-bold">{profile.loanAmount} đ</td>
                    <td className="text-end text-nowrap text-primary fw-bold pe-5">{profile.needPay} đ</td>
                </tr>
            </tbody>
        </table>
    )
}
export { ItemTable }
