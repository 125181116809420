/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { CreditProfile } from '../core/_models'

const profile = {
    Oid: '123',
    code: '1000563945',
    materialNumber: '51H 49.751',
    applyDate: '08/01/2021',
    expireDate: '08/01/2021',
    loanAmount: '450.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000",
}

const MomoWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <div className="text-center">
                <h1 className="m-9">{intl.formatMessage({ id: 'PAY.DomesticMomoSimulator' })}</h1>

                <Link className="btn btn-lg btn-light m-2" type="button" to={'/credit-profile/detail'}>
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-12'>
                            <img className="img-fluid" title="Napas payment" src={toAbsoluteUrl('/media/payments/momo-payment.png')} />
                        </div>
                        {/* begin::Col */}
                    </div>
                    {/* end::Row */}
                </Link>

            </div>
        </>
    )
}

export { MomoWrapper }
