/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { toAbsoluteUrl, KTSVG } from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { EarlyRepaymentList } from './EarlyRepaymentList'
import './earlyrepayment.css';

const EarlyRepaymentPage: FC = () => {
    const intl = useIntl()
    return (
        <>
            <div className="card mt-9">
                <div className="card-header">
                    <h3 className="card-title text-uppercase">{intl.formatMessage({ id: 'EARLYREPAYMENT.liquidationofloancontract' })}</h3>
                </div>
                {/* begin::Col */}
                <div className='col-xl-12 col-md-12'>
                    <div className="early-repayment_content p-9 pt-3">
                        <h5 className="text-primary text-uppercase">{intl.formatMessage({ id: 'EARLYREPAYMENT.PAYINGPAYMENTCONTRACT' })}</h5>
                        <div className="pb-4">Quý khách vui lòng thanh toán số tiền 425.350.000 đ trước ngày 08/01/2022 để thanh lý hợp đồng trong kỳ.</div>
                        <div className="">
                            <h5 className="text-primary text-uppercase">{intl.formatMessage({ id: 'EARLYREPAYMENT.Noteaboutliquidationrequest' })}</h5>
                            <div><span className="fw-bolder">Nếu Quý khách mua xe ở đại lý thuộc khu vực TP.HCM:</span> sau 1 ngày đóng tiền, Chủ xe hoặc Người cùng vay có thể mang bản gốc CMND và Giấy nộp tiền đến VP TFSVN để làm thủ tục thanh lý Hợp đồng và nhận lại Đăng ký gốc.</div>
                            <div className="pt-3"><span className="fw-bolder">Nếu Quý khách mua xe ở đại lý tỉnh:</span> Trong vòng 3 ngày làm việc kể từ ngày thanh lý Hợp đồng, sẽ có nhân viên liên hệ Quý khách để nhận lại Đăng ký gốc tại đại lý Toyota.</div>
                            <div className="pt-3">Trường hợp Quý khách cần kết quả xóa giải chấp, vui lòng thông báo cho chúng tôi qua mục “Yêu cầu hỗ trợ”. Chúng tôi sẽ gửi đến Quý khách sau 1 tuần.</div>
                        </div>
                    </div>
                </div>
                {/* end::Col */}
                <div style={{ backgroundColor: '#fff', color: '#7e9aa6' }}>
                        <h4 className="card-title ms-4 mb-6">{intl.formatMessage({ id: 'EARLYREPAYMENT.PleaseselecttheContract' })}</h4>
                </div>
                <EarlyRepaymentList />
            </div>

        </>
    )
}

const EarlyRepaymentWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
          <EarlyRepaymentPage />
    </>
  )
}

export {EarlyRepaymentWrapper}
