/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { RegistrationStatusMaterial } from '../../core/_models'
import { RegistrationStatusWidget } from './RegistrationStatusWidget'
import { RegistrationStatusTable } from './RegistrationStatusTable'

type Props = {
    
}

const creditProfiles = [
    {
        Oid: '123',
        display: '1',
        materialNumber: '51B - 497.51',
        registerCode: '1',
        applyDate: '08/01/2022',
        expireDate: '08/01/2025',
        toCustomerDate: '08/03/2022',
    },
];

const RegistrationStatusList: FC<Props> = () => (
    <>
        <div className="d-xs-block d-sm-block d-md-block d-xl-none d-xlg-none">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                {creditProfiles.map((registrationStatus: RegistrationStatusMaterial) => (
                    <RegistrationStatusWidget key={registrationStatus.Oid} registrationStatus={registrationStatus} />
                ))}

                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>

        <div className="d-none d-xs-none d-sm-none d-md-none d-xl-block d-xlg-block">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                <RegistrationStatusTable registrationStatus={creditProfiles} />
                
                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>
    </>
)

export { RegistrationStatusList }