/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import {useIntl} from 'react-intl'
import { KTCardBody, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { EarlyRepaymentWrapper } from './EarlyRepaymentWrapper'
import { EarlyRepaymentCalculatorWrapper } from './calculator/EarlyRepaymentCalculatorWrapper'

const EarlyRepaymentRoute: FC = () => {
    return (
        <Routes>
                <Route index
                    element={
                        <>
                            <EarlyRepaymentWrapper />
                        </>
                    }
                />

                <Route
                    path='calculator'
                    element={
                        <>
                            <EarlyRepaymentCalculatorWrapper />
                        </>
                    }
                />
        </Routes>
    )
}

export { EarlyRepaymentRoute }
