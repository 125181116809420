/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { SchedulePaymentCreditProfile } from '../../core/_models'

type Props = {
    payment: SchedulePaymentCreditProfile
}

const SchedulePaymentWidget = ({ payment }: Props) => {
    const intl = useIntl()
    return (
        <div className="card card-bordered mt-0">
            <div className="card-body">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Kỳ thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY1' })}: </span>
                            <span className="text-nowrap">{payment.display}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY2' })}: </span>
                            <span className="text-nowrap">{payment.payDate}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Gốc*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY3' })}: </span>
                            <span className="text-nowrap">{payment.totalAmount} đ</span>
                        </div>
                        <div className="mb-5 mb-md-0  d-flex justify-content-between">
                            <span className="me-2">{/*Dư nợ đầu kỳ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY4' })}: </span>
                            <span className="text-nowrap">{payment.beginAmount} đ</span>
                        </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 ps-md-15'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Lãi suất*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY5' })}: </span>
                            <span className="text-nowrap">{payment.interestRate}</span>

                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Số tiền trả theo kỳ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY6' })} </span>
                            <span className="text-nowrap">{payment.payAmount} đ</span>
                        </div>
                        <div className="mb-0 d-flex justify-content-between">
                            <span className="me-2">{/*Lãi*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.SCHEDULEPAYMENTWIDGET.KEY7' })}: </span>
                            <span className="text-nowrap">{payment.interestAmount} đ</span>
                        </div>

                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
        </div>
    )
}
export { SchedulePaymentWidget }
