/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { SchedulePaymentCreditProfile } from '../../core/_models'
import { SchedulePaymentWidget } from './SchedulePaymentWidget'
import { SchedulePaymentTable } from './SchedulePaymentTable'

type Props = {
    
}

const creditProfiles = [
    {
        Oid: '123',
        display: '1',
        payDate: '08/01/2022',
        interestRate: '12.5 %',
        payAmount: '12.650.000',
        interestAmount: '3.008.219',
        totalAmount: '800.000.000',
        beginAmount: '800.000.000',
    },
    {
        Oid: '1233',
        display: '2',
        payDate: '08/02/2022',
        interestRate: '12.5 %',
        payAmount: '12.650.000',
        interestAmount: '3.008.219',
        totalAmount: '800.000.000',
        beginAmount: '787.350.000',
    },
    {
        Oid: '12344',
        display: '3',
        payDate: '08/03/2022',
        interestRate: '12.5 %',
        payAmount: '12.650.000',
        interestAmount: '3.008.219',
        totalAmount: '800.000.000',
        beginAmount: '774.700.000',
    },
];

const SchedulePaymentList: FC<Props> = () => (
    <>
        <div className="d-xs-block d-sm-block d-md-block d-xl-none d-xlg-none">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                {creditProfiles.map((payment: SchedulePaymentCreditProfile) => (
                    <SchedulePaymentWidget key={payment.Oid} payment={payment} />
                ))}

                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>

        <div className="d-none d-xs-none d-sm-none d-md-none d-xl-block d-xlg-block">
            <div className="ps-0 pt-9 pb-9 container-fluid">
                <SchedulePaymentTable history={creditProfiles} />

                <ul className="pagination mt-9">
                    <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
                    <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
                    <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
        </div>
    </>
)

export { SchedulePaymentList }