/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { SelectPayment } from './SelectPayment'
import { CreditProfile } from '../core/_models'
import { PaymentDetailList } from '../details/payment-detail/PaymentDetailList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import '../creditprofile.css';

const profile = {
    Oid: '123',
    code: '1000563945',
    materialNumber: '51B - 497.51',
    applyDate: '08/01/2021',
    expireDate: '08/01/2021',
    loanAmount: '450.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000",
}

const PayCreditProfileWrapper: FC = () => {
    const intl = useIntl()
    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("type") || "1"

    const [state, setState] = useState({
        value: type
    })

    const handleRadioButton = (value: string) => {
        setState({
            value: value
        });
    }

    const profile = {
        Oid: '123',
        code: '1000563945',
        materialNumber: '51B - 497.51',
        Trademark: 'TOYOTA',
        Carmodel: 'AVANZA PREMIO',
        Version: 'CVT',
        applyDate: '08/01/2022',
        expireDate: '08/01/2025',
        loanAmount: '450.000.000',
        remainAmount: '300.000.000',
        url: '/media/cars/car-01.png',
        description: 'Thông tin khoản vay',
        totalAmout: "800.000.000",
        profitAmout: "500.000",
    }

    return (
        <>
            <div className="card card-bordered mt-9">
                <div className="card-header">
                    <h3 className="card-title"><FontAwesomeIcon icon={faEnvelopeOpenText} className="fs-2s me-2" /> {/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY3' })}: {profile.code}</h3>
                </div>
                <div className="card-body">
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-2 order-xs-2 order-sm-2 order-md-2 order-xl-2'>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} >{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY2' })}:</span>
                                <span className="" style={{ color: '#e10a1d', fontWeight: 'bolder' }}>{profile.materialNumber}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2" >{/*Thương hiệu*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY18' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Trademark}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Mẫu xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY19' })}:</span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.Carmodel}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Phiên bản*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY20' })}:</span>
                                <span className="">{profile.Version}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY4' })}: </span>
                                <span className="">{profile.applyDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Ngày kết thúc hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY5' })}: </span>
                                <span className="">{profile.expireDate}</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Số tiền vay*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY6' })}: </span>
                                <span className="">{profile.loanAmount} đ</span>
                            </div>
                            <div className="m-5 d-flex justify-content-between">
                                <span className="me-2">{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEDETAILWRAPPER.KEY7' })}: </span>
                                <span className="" style={{ fontWeight: 'bolder' }}>{profile.remainAmount} đ</span>
                            </div>
                        </div>
                        {/* end::Col */}
                        {/* begin::Col */}
                        <div className='col-xl-6 col-md-12 order-1 order-xs-1 order-sm-1 order-md-1 order-xl-1'>
                            <img
                                src={toAbsoluteUrl(profile.url)}
                                className='w-100 w-xs-100 w-sm-100 w-md-100 lg-100'
                                alt=''
                            />
                        </div>
                        {/* end::Col */}
                    </div>
                    {/* end::Row */}
                </div>
                <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                    <div className="mb-5">
                        <div
                            className="accordion-header py-3 d-flex"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_2_item_5"
                        >
                            <span className="accordion-icon">
                                <KTSVG
                                    className="svg-icon svg-icon-4"
                                    path="/media/icons/duotune/arrows/arr064.svg"
                                />
                            </span>
                            <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                {/*KẾ HOẠCH THANH TOÁN*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.CREDITPROFILEACCORDION.KEY5' })}
                            </h3>
                        </div>
                        <div
                            id="kt_accordion_2_item_5"
                            className="fs-6 collapse show ps-10"
                            data-bs-parent="#kt_accordion_2"
                            style={{ overflow: 'hidden' }}
                        >
                            <div>
                                <PaymentDetailList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="card card-bordered mt-9">
                <div className="card-header Pay-Credit-Profile_Header">
                    <div className="text-white">{intl.formatMessage({ id: 'PAY.PaymentMethod' })}</div>
                </div>
                <div className="card-body">
                    {/* begin::Row */}
                    <div className='row'>
                        {/* begin::Col */}
                        <div className='col-xl-4 col-md-12 border border-light'>
                            <div className="mb-4">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="payment" value="3" id="flexRadioChecked3"
                                        onChange={() => handleRadioButton("3")}
                                        checked={state.value === "3"}
                                    />
                                    <div className="form-icon m-2 text-center" style={{ width: '70px' }} >
                                        <img src={toAbsoluteUrl('/media/payment/momo.png')} style={{ height: '48px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.MOMO' })} />
                                    </div>
                                    <label className="form-check-label text-black px-2" htmlFor="flexRadioChecked3">
                                        {intl.formatMessage({ id: 'PAY.MomoEWallet' })}
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="payment" value="4" id="flexRadioChecked4"
                                        onChange={() => handleRadioButton("4")}
                                        checked={state.value === "4"}
                                    />
                                    <div className="form-icon m-2 text-center" style={{ width: '70px' }}>
                                        <img src={toAbsoluteUrl('/media/payment/vnpay.png')} style={{ height: '48px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.VNPAY' })} />
                                    </div>
                                    <label className="form-check-label text-black px-2" htmlFor="flexRadioChecked4">
                                        {intl.formatMessage({ id: 'PAY.VNPayEWallet' })}
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="payment" value="2" id="flexRadioChecked2"
                                        onChange={() => handleRadioButton("2")}
                                        checked={state.value === "2"}
                                    />
                                    <div className="form-icon m-2" style={{ width: '70px' }}>
                                        <img src={toAbsoluteUrl('/media/payment/mastercard.png')} style={{ height: '44px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.MASTERCARD' })} />
                                    </div>
                                    <label className="form-check-label text-black px-2" htmlFor="flexRadioChecked2">
                                        {intl.formatMessage({ id: 'PAY.Internationalcardpayment' })}
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="payment" value="1" id="flexRadioChecked"
                                        onChange={() => handleRadioButton("1")}
                                        checked={state.value === "1"}
                                    />
                                    <div className="form-icon m-2" style={{ width: '70px' }}>
                                        <img src={toAbsoluteUrl('/media/payment/ATM.png')} style={{ height: '44px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                    </div>
                                    <label className="form-check-label text-black px-2" htmlFor="flexRadioChecked">
                                        {intl.formatMessage({ id: 'PAY.DomesticATMcardpayment' })}
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="payment" value="4" id="flexRadioChecked5"
                                        onChange={() => handleRadioButton("5")}
                                        checked={state.value === "5"}
                                    />
                                    <div className="form-icon m-2" style={{ width: '70px' }}>
                                        <img src={toAbsoluteUrl('/media/payment/transfer.png')} style={{ height: '44px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.VNPAY' })} />
                                    </div>
                                    <label className="form-check-label text-black px-2" htmlFor="flexRadioChecked5">
                                        {intl.formatMessage({ id: 'PAY.Transfer' })}
                                    </label>
                                </div>
                            </div>
                            {/*<div className="mt-5">*/}
                            {/*    {state.value === "1" && (*/}
                            {/*        <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/pay-napas-ATM'}>*/}
                            {/*            {intl.formatMessage({ id: 'PAY.ContinuewithATM' })}*/}
                            {/*        </Link>*/}
                            {/*    )*/}
                            {/*    }*/}
                            {/*    {state.value === "2" && (*/}
                            {/*        <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/pay-napas-credit'}>*/}
                            {/*            {intl.formatMessage({ id: 'PAY.ContinuewithVisaMaster' })}*/}
                            {/*        </Link>*/}
                            {/*    )*/}
                            {/*    }*/}
                            {/*    {state.value === "3" && (*/}
                            {/*        <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/pay-momo'}>*/}
                            {/*            {intl.formatMessage({ id: 'PAY.ContinuewithMomo' })}*/}
                            {/*        </Link>*/}
                            {/*    )*/}
                            {/*    }*/}
                            {/*    {state.value === "4" && (*/}
                            {/*        <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/pay-vnpay'}>*/}
                            {/*            {intl.formatMessage({ id: 'PAY.ContinuewithVNPAY' })}*/}
                            {/*        </Link>*/}
                            {/*    )*/}
                            {/*    }*/}
                            {/*    {state.value === "5" && (*/}
                            {/*        <Link className="btn btn-lg btn-primary m-2" type="button" to={'/credit-profile/transfer'}>*/}
                            {/*            {intl.formatMessage({ id: 'PAY.ContinuewithTransfer' })}*/}
                            {/*        </Link>*/}
                            {/*    )*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                        <div className='col-xl-8 col-md-12 border border-light'>
                            <div className="mt-5">
                                {state.value === "1" && (
                                    <div className="Pay-Credit-Profile">
                                        <div className="form-icon">
                                            <img src={toAbsoluteUrl('/media/payment/ATM.png')} style={{ height: '78px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                        </div>
                                        <div className="OnlinePayment_Item--Content">
                                            <div className="OnlinePayment_Item--Title mb-2">
                                                {intl.formatMessage({ id: 'PAY.HowtopayviaATM' })}<br />
                                            </div>
                                            <div>
                                                Quý khách có thể thanh toán trực tuyến với thẻ nội địa bằng cách nhập
                                                thông tin thẻ trực tiếp khi đến bước yêu cầu thanh toán.
                                                Thông tin bao gồm số thẻ, tên chủ thẻ, ngày mở thẻ và số CVV nằm ở mặt sau thẻ.
                                                Ngoài ra, quý khách cũng có thể liên kết thẻ nội địa thông qua
                                                các ví điện tử hiện nay để sử dụng.
                                            </div>
                                        </div>
                                        <Link className="btn btn-lg btn-primary m-4 float-end" type="button" to={'/credit-profile/pay-napas-ATM'}>
                                            {intl.formatMessage({ id: 'PAY.ContinuewithATM' })}
                                        </Link>
                                    </div>
                                )
                                }
                                {state.value === "2" && (
                                    <div className="Pay-Credit-Profile">
                                        <div className="form-icon">
                                            <img src={toAbsoluteUrl('/media/payment/mastercard.png')} style={{ height: '78px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                        </div>
                                        <div className="OnlinePayment_Item--Content">
                                            <div className="OnlinePayment_Item--Title mb-2">
                                                {intl.formatMessage({ id: 'PAY.HowtopayviaVISA' })}<br />
                                            </div>
                                            <div>
                                                Quý khách có thể thanh toán trực tuyến với thẻ Visa bằng cách nhập thông tin thẻ trực tiếp
                                                khi đến bước yêu cầu thanh toán. Thông tin bao gồm số thẻ, tên chủ thẻ, ngày mở thẻ và
                                                số CVV nằm ở mặt sau thẻ. Ngoài ra, quý khách cũng có thể liên kết thẻ Visa thông qua các
                                                ví điện tử hiện nay để sử dụng.
                                            </div>
                                        </div>
                                        <Link className="btn btn-lg btn-primary m-4 float-end" type="button" to={'/credit-profile/pay-napas-credit'}>
                                            {intl.formatMessage({ id: 'PAY.ContinuewithVisaMaster' })}
                                        </Link>
                                    </div>
                                )
                                }
                                {state.value === "3" && (
                                    <div className="Pay-Credit-Profile">
                                        <div className="form-icon">
                                            <img src={toAbsoluteUrl('/media/payment/momo.png')} style={{ height: '78px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                        </div>
                                        <div className="OnlinePayment_Item--Content">
                                            <div className="OnlinePayment_Item--Title mb-2">
                                                {intl.formatMessage({ id: 'PAY.HowtopayviaMOMO' })}<br />
                                            </div>
                                            <div>
                                                <div className=""><span className="fw-bolder">1. Quét mã:</span> Tại quầy thanh toán, thu ngân sẽ cung cấp 1 mã QR.
                                                    Người dùng mở ứng dụng Ví MoMo và chọn "Quét Mã".
                                                    Sau khi quét mã của thu ngân, khách hàng nhập số tiền cần thanh toán và bấm xác nhận.</div>
                                                <div className="mt-4"><span className="fw-bolder">2. Mã thanh toán:</span> Với phương thức này, quý khách mở ứng dụng và chọn "Mã thanh toán".
                                                    1 mã thanh toán sẽ xuất hiện trên màn hình.
                                                    Quý khách đưa mã đó cho thu ngân để quét và hoàn tất thanh toán.</div>
                                            </div>
                                        </div>
                                        <Link className="btn btn-lg btn-primary m-4 float-end" type="button" to={'/credit-profile/pay-momo'}>
                                            {intl.formatMessage({ id: 'PAY.ContinuewithMomo' })}
                                        </Link>
                                    </div>
                                )
                                }
                                {state.value === "4" && (
                                    <div className="Pay-Credit-Profile">
                                        <div className="form-icon">
                                            <img src={toAbsoluteUrl('/media/payment/vnpay.png')} style={{ height: '78px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                        </div>
                                        <div className="OnlinePayment_Item--Content">
                                            <div className="OnlinePayment_Item--Title mb-2">
                                                {intl.formatMessage({ id: 'PAY.HowtopayviaVNPAY' })}<br />
                                            </div>
                                            <div>
                                                1. Nếu Quý khách chưa có ví điện tử VNPAY, xin vui lòng:<br />
                                                Bước 1: Tạo tài khoản ví điện tử VNPAY, bằng cách bấm vào “Tạo ví điện tử VNPAY” và thực hiện theo hướng dẫn.<br />
                                                Bước 2: Quay lại trang Toyota Finance và thực hiện thanh toán, bằng cách bấm vào “Thanh toán ngay” và thực hiện theo hướng dẫn của chương trình.<br />
                                                2. Nếu Quý khách đã có ví điện tử VNPAY, Quý khách thực hiện thanh toán bằng tài khoản
                                                ví điện tử VNPAY, bằng cách bấm vào “Thanh toán ngay” và thực hiện theo hướng dẫn của
                                                chương trình.
                                            </div>
                                        </div>
                                        <Link className="btn btn-lg btn-primary m-4 float-end" type="button" to={'/credit-profile/pay-vnpay'}>
                                            {intl.formatMessage({ id: 'PAY.ContinuewithVNPAY' })}
                                        </Link>
                                    </div>
                                )
                                }
                                {state.value === "5" && (
                                    <div className="Pay-Credit-Profile">
                                        <div className="form-icon">
                                            <img src={toAbsoluteUrl('/media/payment/transfer.png')} style={{ height: '78px' }} alt={intl.formatMessage({ id: 'CREDITPROFILE.SELECTPAYMENT.ATM' })} />
                                        </div>
                                        <div className="OnlinePayment_Item--Content">
                                            <div className="OnlinePayment_Item--Title mb-2">
                                                {intl.formatMessage({ id: 'PAY.HowtopayviaTransfer' })}<br />
                                            </div>
                                            <div>
                                                <div>Người thụ hưởng: Nguyễn Văn B</div>
                                                <div>Số tài khoản: xxxx-xxx-xxxxxx</div>
                                                <div>Nơi mở tài khoản: Ngân hàng VB Bank</div>
                                                <div>Nội dung chuyển khoản: Thanh toán đơn hàng xxxxxx</div>
                                            </div>
                                        </div>
                                        <Link className="btn btn-lg btn-primary m-4 float-end" type="button" to={'/credit-profile/transfer'}>
                                            {intl.formatMessage({ id: 'PAY.ContinuewithTransfer' })}
                                        </Link>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                        {/* end::Col */}
                    </div>
                    {/* end::Row */}
                </div>
            </div>


            

        </>
    )
}

export { PayCreditProfileWrapper }
