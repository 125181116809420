/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../_metronic/helpers'
import { EarlyRepaymentCreditProfile } from '../../credit-profile/core/_models'

const profile = {
    Oid: '123',
    code: '1000563945',
    materialNumber: '51B - 497.51',
    applyDate: '08/01/2021',
    expireDate: '08/01/2021',
    loanAmount: '800.000.000',
    remainAmount: '300.000.000',
    url: '/media/cars/car-01.png',
    description: 'Thông tin khoản vay',
    totalAmout: "800.000.000",
    profitAmout: "500.000đ",
    interestRate: "12.5 %",
    earlyInterestRate: "15.0 %"
}

const calculator = {
    interestAmout: "62.576.000",//Lãi phải trả
    earlyMonetaryFine: "5.399.521", //Phạt trả nợ sớm
    lateMonetaryFine: "0", //Phạt trễ hạn
    remainAmount: "0", //Tiền dư trên hợp đồng
    totalNeedPay: "459.956.000" //TỔNG SỐ TIỀN CẦN THANH TOÁN
}

const CalculatorOption2: FC = () => {
    const intl = useIntl()
    return (
        <>

            <div className="justify-content-between">
                <div className="mb-10">
                    <label className="form-label">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Prepayment' })}:</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.EnterPrepaymentAmount' })}
                    />
                </div>
            </div>
            <div className="mt-5 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6'>
                        <span className="me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.PrepaymentPenalty' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 text-end'>
                        <span className="text-end">{calculator.earlyMonetaryFine} đ</span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>

            <div className="mt-10 justify-content-between">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-6'>
                        <span className="h5 me-2">{intl.formatMessage({ id: 'EARLYREPAYMENTCALCULATOR.Totalpayable' })}: </span>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-6 h5 text-end'>
                        <span className="text-end">{calculator.totalNeedPay} đ</span>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>


        </>
    )
}

export { CalculatorOption2 }
