/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { CreditProfile } from './core/_models'
import { CreditProfileWidget } from './CreditProfileWidget'

type Props = {
    
}

const creditProfiles = [
    {
        Oid: '123',
        code: '1000563945',
        materialNumber: '51B - 497.51',
        applyDate: '08/01/2022',
        expireDate: '08/01/2025',
        loanAmount: '450.000.000',
        remainAmount: '300.000.000',
        needPay: '12.672.809',
        url: '/media/cars/car-01.png',
        description: 'Thông tin khoản vay'
    },
    {
        Oid: '1234',
        code: '1000693715',
        materialNumber: '52B - 527.41',
        applyDate: '08/01/2022',
        expireDate: '08/01/2025',
        loanAmount: '450.000.000',
        remainAmount: '300.000.000',
        needPay: '374.650.000',
        url: '/media/cars/car-02.png',
        description: 'Thông tin khoản vay'
    }
];

const CreditProfileList: FC<Props> = () => (
    <>
        {creditProfiles.map((profile: CreditProfile) => (
            <CreditProfileWidget key={profile.Oid} profile={profile} />
        ))}

        <ul className="pagination mt-9">
            <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>
            <li className="page-item disabled active"><a href="#" className="page-link">1</a></li>
            <li className="page-item disabled"><a href="#" className="page-link">2</a></li>
            <li className="page-item disabled"><a href="#" className="page-link">3</a></li>
            <li className="page-item disabled next"><a href="#" className="page-link"><i className="next"></i></a></li>
        </ul>
    </>
)

export {CreditProfileList}