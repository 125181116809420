/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { PaymentDetailCreditProfile } from '../../core/_models'

type Props = {
    paymentDetail: any
}

const PaymentDetailTable: React.FC<Props> = ({ paymentDetail }) => {
const intl = useIntl()
    return (
        <table className="table border table-rounded table-row-dashed table-row-gray-300 gy-3 gs-3">
            <thead>
                <tr className="fs-6 border border-light text-uppercase text-center"
                    style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}
                >
                    <th>{/*Kỳ thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY1' })}</th>
                    <th>{/*Ngày thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY2' })}</th>
                    <th>{/*Lãi suất*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY3' })}</th>
                    <th>{/*Lãi*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY5' })}</th>
                    <th>{/*Số tiền trả theo kỳ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY4' })}</th>
                    <th>{/*Dư nợ đầu kỳ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY7' })}</th>
                    <th>{/*Gốc*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.PAYMENTDETAILTABLE.KEY6' })}</th>
                </tr>
            </thead>
            <tbody>
                {paymentDetail.map((item: PaymentDetailCreditProfile) => (
                    <tr key={"scheduleitem" + item.Oid} className="border border-light text-center">
                        <td className="text-nowrap">{item.display}</td>
                        <td className="text-nowrap">{item.payDate}</td>
                        <td className="text-nowrap">{item.interestRate}</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.payAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.interestAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.totalAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.beginAmount} đ</td>
                    </tr>
                ))}
            </tbody>
        </table>
        )
}
export { PaymentDetailTable }
