/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import {useIntl} from 'react-intl'
import { KTCardBody, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { CreditProfileSlideWidget } from './CreditProfileSlideWidget'
import { CreditProfileList } from './CreditProfileList'
import './creditprofile.css';
const CreditProfileWrapper: FC = () => {
  const intl = useIntl()
    return (
    <>
          <CreditProfileSlideWidget className='h-md-100' />
            <div style={{ backgroundColor: '#fff' }}>
                <div className="credit-profile_header d-flex">
                    <KTSVG path='/media/icons/duotune/art/icon-menu2.svg' className='credit-profile_header-icon svg-icon-1' />
                    <h3>{intl.formatMessage({ id: 'CREDITPROFILE.LOANCONTRACTLIST' })}</h3>
                </div>
                <CreditProfileList />
            </div>
    </>
  )
}

export {CreditProfileWrapper}
