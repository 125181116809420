/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { InsuranceCreditProfile } from '../../core/_models'

type Props = {
    insurance: any
}

const InsuranceTable: React.FC<Props> = ({ insurance }) => {
    const intl = useIntl()
    return (
        <table className="table border table-rounded table-row-dashed table-row-gray-300 gy-3 gs-3">
            <thead>
                <tr className="fs-6 border border-light text-uppercase text-center"
                    style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}
                >
                    <th>{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY1' })}</th>
                    <th>{/*Số hợp đồng BH*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY2' })}</th>
                    <th>{/*Cty Bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY3' })}</th>
                    <th>{/*Ngày hiệu lực hợp đồng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY4' })}</th>
                    <th>{/*Ngày hết hạn bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY5' })}</th>
                    <th>{/*Số tiền bảo hiểm (VND)*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY6' })}</th>
                    <th>{/*Phí BH (VND)*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.INSURANCETABLE.KEY7' })}</th>
                </tr>
            </thead>
            <tbody>
                {insurance.map((item: InsuranceCreditProfile) => (
                    <tr key={"insuranceitem" + item.Oid} className="border border-light text-center">
                        <td className="text-nowrap">{item.materialNumber}</td>
                        <td className="text-nowrap">{item.insuranceCode}</td>
                        <td className="text-nowrap">{item.insuranceName}</td>
                        <td className="text-nowrap">{item.applyDate}</td>
                        <td className="text-nowrap">{item.expireDate}</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.insuranceAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.feeAmount} đ</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
export { InsuranceTable }
