/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { SidebarMenuItemClick } from './SidebarMenuItemClick'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  return (
      <>
        <SidebarMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/art/icon-menu1.svg'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
            arrow='/media/icons/duotune/art/arrow_line.svg'
          />
        <SidebarMenuItem
            to='/credit-profile'
            icon='/media/icons/duotune/art/icon-menu2.svg'
            title={intl.formatMessage({ id: 'MENU.THONGTINKHOANVAY' })}
            fontIcon='bi-app-indicator'
            arrow='/media/icons/duotune/art/arrow_line.svg'
        />
        <SidebarMenuItem
            to='/online-payment'
            icon='/media/icons/duotune/art/icon-menu3.svg'
            title={intl.formatMessage({ id: 'MENU.THANHTOANTRUCTUYEN' })}
            fontIcon='bi-app-indicator'
            arrow='/media/icons/duotune/art/arrow_line.svg'
          />
          <SidebarMenuItem
              to='/early-repayment'
              icon='/media/icons/duotune/art/icon-menu4.svg'
              title={intl.formatMessage({ id: 'MENU.TINHTOANTRANOSOM' })}
              fontIcon='bi-app-indicator'
              arrow='/media/icons/duotune/art/arrow_line.svg'
          />
          <SidebarMenuItem
              to='/support'
              icon='/media/icons/duotune/art/icon-menu5.svg'
              title={intl.formatMessage({ id: 'MENU.YEUCAUHOTRO' })}
              fontIcon='bi-app-indicator'
              arrow='/media/icons/duotune/art/arrow_line.svg'
          />
          
    </>
  )
}

export {SidebarMenuMain}
