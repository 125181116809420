/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { RegistrationStatusMaterial } from '../../core/_models'

type Props = {
    registrationStatus: RegistrationStatusMaterial
}

const RegistrationStatusWidget = ({ registrationStatus }: Props) => {
    const intl = useIntl()
    return (
        <div className="card card-bordered mt-0">
            <div className="card-body">
                {/* begin::Row */}
                <div className='row'>
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Biển số xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSWIDGET.KEY1' })}: </span>
                            <span className="text-nowrap">{registrationStatus.materialNumber}</span>
                        </div>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Lần gia hạn*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSWIDGET.KEY2' })}: </span>
                            <span className="text-nowrap">{registrationStatus.registerCode}</span>
                        </div>
                        <div className="mb-5 mb-md-0 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày hiệu lực đăng ký xe*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSWIDGET.KEY3' })}: </span>
                            <span className="text-nowrap">{registrationStatus.applyDate}</span>
                        </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div className='col-xl-6 col-md-6 col-sm-12 col-xs-12 m-0 p-0 ps-md-15'>
                        <div className="mb-5 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày hết hạn bảo hiểm*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSWIDGET.KEY4' })}: </span>
                            <span className="text-nowrap">{registrationStatus.expireDate}</span>
                        </div>
                        <div className="mb-0 d-flex justify-content-between">
                            <span className="me-2">{/*Ngày TFSVN gửi khách hàng*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.REGISTRATIONSTATUSWIDGET.KEY5' })}: </span>
                            <span className="text-nowrap">{registrationStatus.toCustomerDate} đ</span>
                        </div>
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
        </div>
    )
}
export { RegistrationStatusWidget }
