/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import {Link} from 'react-router-dom'
import { toAbsoluteUrl, checkIsActive, KTSVG, WithChildren } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { HistoryCreditProfile } from '../../core/_models'

type Props = {
    history: any
}

const HistoryTable: React.FC<Props> = ({ history }) => {
const intl = useIntl()
    return (
        <table className="table border table-rounded table-row-dashed table-row-gray-300 gy-3 gs-3">
            <thead>
                <tr className="fs-6 border border-light text-uppercase text-center"
                    style={{ fontWeight: '500', color: '#7e9aa6', backgroundColor: '#f4f8fb' }}
                >
                    <th>{/*Kỳ thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY1' })}</th>
                    <th>{/*Ngày thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY2' })}</th>
                    <th>{/*Số tiền thanh toán*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY3' })}</th>
                    <th>{/*Dư nợ đầu kỳ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY4' })}</th>
                    <th>{/*Dư nợ*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY5' })}</th>
                    <th>{/*Gốc*/}{intl.formatMessage({ id: 'CREDITPROFILE.DETAIL.SCHEDULEPAYMENT.HISTORYTABLE.KEY6' })}</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item: HistoryCreditProfile) => (
                    <tr key={"historyitem" + item.Oid} className="border border-light text-center">
                        <td className="text-nowrap" >{item.display}</td>
                        <td className="text-nowrap" >{item.payDate}</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.payAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.beginAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.remainAmount} đ</td>
                        <td className="text-nowrap text-end" style={{ color: '#e10a1d', fontWeight: '500' }}>{item.totalAmount} đ</td>
                    </tr>
                ))}
            </tbody>
        </table>
        )
}
export { HistoryTable }
