import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import './Auth.css';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { requestPassword } from '../core/_requests'
import { useIntl } from 'react-intl'
import ReCAPTCHA from "react-google-recaptcha";

const initialValues = {
    email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Sai định dạng Số CMND/CCCD/Mã số thuế')
        .min(3, 'Nhập tối thiểu 3 ký tự')
        .max(50, 'Nhập tối đa 50 ký tự')
        .required('Vui lòng nhập Số CMND/CCCD/Mã số thuế'),
})

export function ForgotPassword() {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                requestPassword(values.email)
                    .then(({ data: { result } }) => {
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })
    const sitekey = "6LdOsR8lAAAAABC0IiALLI2EdbAZvnF4LNXeChmQ";
    const onChange = () => {
        if (!sitekey) {
            console.log("1");
        }
        else console.log("2");
    };
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <a
                href='https://toyotafinance.com.vn/#'
                className='logo-auth d-flex justify-content-center mb-12'
            >
                <img
                    src={toAbsoluteUrl('/media/misc/logo-auth-bg.png')}
                    style={{ width: '234px', height: '74px' }}
                >
                </img>
            </a>
            <div className='text-center'>
                <p className='auth_title text-white fw-semibold'>{intl.formatMessage({ id: 'AUTH.FORGOTPASSWORD.KEY1' })}</p>
            </div>
            {/* end::Heading */}

            <div className='form_login'>
                {/* begin::Form group */}
                <div className='fv-row mb-2' >
                    <input
                        placeholder={intl.formatMessage({ id: 'AUTH.FORGOTPASSWORD.KEY2' })}
                        //{...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control',
                            { 'is-invalid': formik.touched.email && formik.errors.email },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}


                {/*begin::Recaptcha */}
                <div className="auth_recaptcha d-flex justify-content-center mb-8">
                    <ReCAPTCHA
                        sitekey={sitekey}
                        onChange={onChange}
                    />
                </div>
                {/* end::Recaptcha */}

                {/* begin::Action */}
                <div className='auth_action d-grid'>
                    <Link
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        to='/auth/forgot-password-otp'
                    >
                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.FORGOTPASSWORD.KEY3' })}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Vui lòng đợi trong giây lát...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Link>
                    {/*<a*/}
                    {/*    type='submit'*/}
                    {/*    id='kt_sign_in_submit'*/}
                    {/*    className='btn btn-primary'*/}
                        
                    {/*>*/}
                    {/*    {!loading && <span className='indicator-label'>Gửi yêu cầu</span>}*/}
                    {/*    {loading && (*/}
                    {/*        <span className='indicator-progress' style={{ display: 'block' }}>*/}
                    {/*            Vui lòng đợi trong giây lát...*/}
                    {/*            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
                    {/*        </span>*/}
                    {/*    )}*/}
                    {/*</a>*/}
                </div>
                {/* end::Action */}
            </div>

            {/*<div className='text-gray-500 text-center fw-semibold fs-6'>*/}
            {/*    Tạo tài khoản mới?{' '}*/}
            {/*    <Link to='/auth/registration' className='link-primary'>*/}
            {/*        Đăng ký*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </form>
    )
}
